import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import Logo from "../../Assests/images/mainLogo.png";
import {
  server_post_data,
  save_amount_pay_remaing,
  get_all_customer_booking,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleIaphabetnumberChange,
  handleNumbersDecimalChange,
  handleSuccessSession,
  handleError,
  inputdateformateChange,
  handleLinkClick,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
function CustomerBookingInvoice() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editBlogData, seteditBlogData] = useState([]);
  const [MenulistSelectedData, setMenulistSelectedData] = useState([]);
  const [ProcesslistData, setProcesslistData] = useState([]);
  const [data_eventtiming, setdata_eventtiming] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [RUPESSICON, setRUPESSICON] = useState("₹");
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedAdminName = retrieveData("admin_name");
  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    master_data_get(start_date, end_date, flag, call_id);
  }, []);
  // console.log(MenulistSelectedData);
  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_customer_booking, fd)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_main_lead.length > 0) {
            seteditBlogData(Response.data.message.data_main_lead[0]);
            console.log(Response.data.message.data_main_lead[0]);
            setEditorDatMainID(
              Response.data.message.data_main_lead[0].primary_id
            );
          }
          setMenulistSelectedData(Response.data.message.data_menuselectlist[0]);
          setProcesslistData(Response.data.message.data_legerAmount);
          setRUPESSICON(Response.data.message.rupess_icon);
          setdata_eventtiming(Response.data.message.data_eventtiming);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const packageAmount = parseFloat(editBlogData.package_amount);
  const addOnAmount = parseFloat(editBlogData.add_on_amount);
  const subTotalAmount = packageAmount + addOnAmount;
  const formattedSubTotal = subTotalAmount.toFixed(2);

  const handlePrintInvoice = () => {
    const printContent = document.querySelector(".invoice_Parent_Container");
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContent.outerHTML;
    window.print();
    document.body.innerHTML = originalContents;
  };
  console.log(editBlogData);
  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader className="hideOnPrint" />

      <div className="app-main">
        <AdminSidebar className="hideOnPrint" />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title hideOnPrint">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Confirm Booking Dashboard
                    <div className="page-title-subheading">Invoice</div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="mb-2">
                <div className="invoice_Parent_Container">
                  <table className="fullTable "></table>
                  <table align="center">
                    <tbody>
                      <tr>
                        <td>
                          <table width={600} align="center">
                            <tbody>
                              <tr></tr>
                              <tr></tr>
                              <tr>
                                <td className="px-5 my-4 py-4">
                                  <table width={503}>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table width={220} align="left">
                                            <tbody>
                                              <tr>
                                                <td align="left">
                                                  <img
                                                    src={Logo}
                                                    width={100}
                                                    height={32}
                                                    alt="logo"
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td height={40} />
                                              </tr>
                                              <tr>
                                                <td height={20} />
                                              </tr>
                                              <tr>
                                                <td className="invoiceThankyouMessage">
                                                  Hello{" "}
                                                  {editBlogData.customer_person_name ||
                                                    ""}
                                                  ,
                                                  <br /> Thank you for Booking
                                                  with us. Wishing you a
                                                  memorable event filled with
                                                  joy and celebration.
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table width={220} align="right">
                                            <tbody>
                                              <tr>
                                                <td height={20} />
                                              </tr>
                                              <tr>
                                                <td height={5} />
                                              </tr>
                                              <tr>
                                                <td className="invoiceSection">
                                                  Invoice
                                                </td>
                                              </tr>

                                              <tr>
                                                <td height={50} />
                                              </tr>
                                              <tr>
                                                <td height={20} />
                                              </tr>
                                              <tr>
                                                <td className="invoiceOrderNumber pe-2">
                                                  <small>
                                                    #000
                                                    {editBlogData.main_lead_id ||
                                                      " "}
                                                  </small>
                                                  <br />
                                                  <small>
                                                    {inputdateformateChange(
                                                      editBlogData.entry_date
                                                    ) || " "}
                                                  </small>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td height={40} />
                              </tr>
                              <tr></tr>
                              <tr>
                                <td height={20} />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* /Order Details */}
                  {/* Total */}
                  <table align="center">
                    <tbody>
                      <tr>
                        <td>
                          <table width={600} align="center">
                            <tbody>
                              <td>
                                <table width={503} align="center">
                                  <tbody>
                                    <tr>
                                      <th className="invoiceItemProperties">
                                        Package Item
                                      </th>
                                      <th className="invoiceItemProperties"></th>
                                      <th className="invoiceItemProperties"></th>
                                      <th className="invoiceItemProperties text-end">
                                        Subtotal
                                      </th>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{ background: "#bebebe" }}
                                        colSpan={4}
                                      />
                                    </tr>
                                    <tr>
                                      <td height={10} />
                                    </tr>
                                    {/* {MenulistSelectedData.map(
                                      (blogdddddd, index) => (
                                        <tr key={index}>
                                          <td className="itemData">
                                            {blogdddddd.menu_name}
                                          </td>
                                          <td
                                            className="itemData"
                                            style={{
                                              color: "#646a6e",
                                            }}
                                          >
                                            <small></small>
                                          </td>
                                          <td
                                            className="itemData text-center"
                                            style={{
                                              color: "#646a6e",
                                            }}
                                          ></td>
                                          <td
                                            className="itemData text-end"
                                            style={{
                                              color: "#1e2b33",
                                            }}
                                          >
                                            {RUPESSICON}{" "}
                                            {blogdddddd.menu_discount_price >
                                            0 ? (
                                              <span>
                                                {blogdddddd.menu_discount_price}
                                              </span>
                                            ) : (
                                              <span>
                                                {blogdddddd.menu_price}
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )} */}
                                    <tr>
                                      <td className="itemData">
                                        Package Amount
                                      </td>
                                      <td
                                        className="itemData"
                                        style={{
                                          color: "#646a6e",
                                        }}
                                      >
                                        <small></small>
                                      </td>
                                      <td
                                        className="itemData text-center"
                                        style={{
                                          color: "#646a6e",
                                        }}
                                      ></td>
                                      <td
                                        className="itemData text-end"
                                        style={{
                                          color: "#1e2b33",
                                        }}
                                      >
                                        {RUPESSICON}{" "}
                                        {editBlogData.package_amount || " "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="itemData">
                                        Service Addon
                                      </td>
                                      <td
                                        className="itemData"
                                        style={{
                                          color: "#646a6e",
                                        }}
                                      >
                                        <small></small>
                                      </td>
                                      <td
                                        className="itemData text-center"
                                        style={{
                                          color: "#646a6e",
                                        }}
                                      ></td>
                                      <td
                                        className="itemData text-end"
                                        style={{
                                          color: "#1e2b33",
                                        }}
                                      >
                                        {RUPESSICON}{" "}
                                        {editBlogData.add_on_amount || " "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        height={1}
                                        colSpan={4}
                                        style={{
                                          borderBottom: "1px solid #e4e4e4",
                                        }}
                                      />
                                    </tr>

                                    <tr>
                                      <td
                                        height={1}
                                        colSpan={4}
                                        style={{
                                          borderBottom: "1px solid #e4e4e4",
                                        }}
                                      />
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <tr>
                                <td>
                                  {/* Table Total */}
                                  <table width={503} align="center">
                                    <tbody>
                                      <tr>
                                        <td className="finalBilling">
                                          Subtotal
                                        </td>
                                        <td className="finalBilling">
                                          {RUPESSICON}
                                          {formattedSubTotal || " "}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="finalBilling">
                                          <small>
                                            GST({editBlogData.gst_per}%)
                                          </small>
                                        </td>
                                        <td className="finalBilling">
                                          <small>
                                            {RUPESSICON}{" "}
                                            {editBlogData.gst_amount || ""}
                                          </small>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="finalBilling"
                                          style={{
                                            color: "#000",
                                          }}
                                        >
                                          <strong>
                                            Grand Total (Incl.Tax)
                                          </strong>
                                        </td>
                                        <td
                                          className="finalBilling"
                                          style={{
                                            color: "#000",
                                          }}
                                        >
                                          <strong>
                                            {RUPESSICON}{" "}
                                            {editBlogData.final_amount || ""}
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* /Table Total */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* /Total */}
                  {/* Information */}
                  <table width={600} align="center">
                    <tbody>
                      <tr>
                        <td>
                          <table width={600} align="center">
                            <tbody>
                              <tr></tr>

                              <tr>
                                <td height={40} />
                              </tr>
                              <tr>
                                <td>
                                  <table width={503} align="center">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table width={220} align="left">
                                            <tbody>
                                              <tr>
                                                <td className="otherInformation text-start">
                                                  <strong>
                                                    BILLING INFORMATION
                                                  </strong>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="100%" height={10} />
                                              </tr>
                                              <tr>
                                                <td className="otherInformation text-start">
                                                  {editBlogData.lead_person_company_name ||
                                                    ""}
                                                  <br />
                                                  City:{" "}
                                                  {editBlogData.person_address ||
                                                    ""}
                                                  <br /> Mobile No:{" "}
                                                  {editBlogData.person_mobile_no ||
                                                    ""}
                                                  <br /> Email:{" "}
                                                  {editBlogData.person_email_id ||
                                                    ""}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table width={220} align="Right">
                                            <tbody>
                                              <tr>
                                                <td className="otherInformation">
                                                  <strong>
                                                    BOOKING DETAILS
                                                  </strong>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="100%" height={10} />
                                              </tr>
                                              <tr>
                                                <td className="otherInformation">
                                                  No of Guest :{" "}
                                                  {editBlogData.event_no_of_person_come ||
                                                    ""}
                                                  <br />
                                                  Event Date :{" "}
                                                  {editBlogData.event_date ||
                                                    ""}
                                                  <br /> Event Time :{" "}
                                                  {
                                                    editBlogData.event_timing_from
                                                  }
                                                  -
                                                  {editBlogData.event_timing_to}
                                                  {/* {data_eventtiming.map(
                                                    (data_get) => {
                                                      if (
                                                        Number(
                                                          data_get.primary_id
                                                        ) ===
                                                        Number(
                                                          editBlogData.customer_for_eventtiming_id
                                                        )
                                                      ) {
                                                        return (
                                                          <span
                                                            key={
                                                              data_get.primary_id
                                                            }
                                                          >
                                                            {
                                                              data_get.timing_name
                                                            }{" "}
                                                            (
                                                            {
                                                              data_get.timing_from
                                                            }{" "}
                                                            -{" "}
                                                            {data_get.timing_to}
                                                            )
                                                          </span>
                                                        );
                                                      }
                                                      return null; // You may want to handle the case when there is no match
                                                    }
                                                  )} */}
                                                  <br /> Venue:{" "}
                                                  {MenulistSelectedData.venue_name ||
                                                    ""}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <table width={503} align="center">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table width={260} align="left">
                                            <tbody>
                                              <tr>
                                                <td height={35} />
                                              </tr>
                                              <tr>
                                                <td height={20} />
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table className="otherInformation">
                                            <tbody>
                                              <tr>
                                                <td height={35} />
                                              </tr>
                                              <tr>
                                                <td height={20} />
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td height={30} />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="otherInformation px-5 py-4">
                          Have a nice day !
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="printzbtn">
                    <button
                      className="btn d-print-none"
                      onClick={handlePrintInvoice}
                    >
                      Print/Download Invoice
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter className="hideOnPrint" />
        </div>
      </div>
    </div>
  );
}
export default CustomerBookingInvoice;
