import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  get_booking_status,
  delete_update_data,
} from "../../ServiceConnection/serviceconnection.js";
import {
  handleLinkClick,
  inputdateformateChange,
  handleConfimDeleteClick,
  computeTodayDate,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { Link } from "react-router-dom";
import DeletIcon from "../../Assests/images/delete.png";
function BookingStatusView() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [BlogData, setsBlogData] = useState([]);
  const [rupess_icon, setrupess_icon] = useState("");
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedVenderId = retrieveData("vendor_id");
  const admin_profession = retrieveData("admin_profession");
  const venue_id = retrieveData("Venue_id");
  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    const call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("vendor_id", retrievedVenderId);
    fd.append("admin_vender", admin_profession);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("select_resource", "All");
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    // fd.append("venue_id", venue_id);
    await server_post_data(get_booking_status, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setsBlogData(Response.data.message.data_main_lead);
          setrupess_icon(Response.data.message.rupess_icon);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  //   const master_data_action = async (call_id) => {
  //     if (handleConfimDeleteClick()) {
  //       setshowLoaderAdmin(true);
  //       const fd = new FormData();
  //       fd.append("id_for_delete", call_id);
  //       fd.append("flag_for", "14");
  //       fd.append("for_status_final", "d");
  //       await server_post_data(delete_update_data, fd)
  //         .then((Response) => {
  //           setshowLoaderAdmin(false);
  //           console.log(Response.data);
  //           if (Response.data.error) {
  //             handleError(Response.data.message);
  //           } else {
  //             master_data_get("", "", "6", "0");
  //           }
  //         })
  //         .catch((error) => {
  //           setshowLoaderAdmin(false);
  //         });
  //     }
  //   };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    BooKing Dashboard
                    <div className="page-title-subheading">
                      Where You Can Check Your Booking
                    </div>
                  </div>
                </div>
                {/* <div className="page-title-actions add_funtion_call">
                  <Link onClick={() => handleLinkClick("/enquiryDashboardAdd")}>
                    <button
                      type="button"
                      className="btn-shadow mr-3 btn btn-dark"
                    >
                      <i className="fa fa-plus">&nbsp;Add Enquiry</i>
                    </button>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div class="mb-3 card">
                <div class="card-header">
                  <ul class="nav nav-justified">
                    <li class="nav-item">
                      <a
                        data-toggle="tab"
                        href="#tab-eg7-0"
                        class="nav-link active show"
                      >
                        Today's Booking
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        data-toggle="tab"
                        href="#tab-eg7-2"
                        class="nav-link show"
                      >
                        Upcoming Booking
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        data-toggle="tab"
                        href="#tab-eg7-1"
                        class="nav-link show"
                      >
                        Older Booking
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="tab-content">
                    <div
                      className="tab-pane active show "
                      id="tab-eg7-0"
                      role="tabpanel"
                    >
                      <div className=" row">
                        {BlogData.filter(
                          (cake) => cake.event_date === computeTodayDate()
                        ).map((blogddd, index) => (
                          <div className="col-md-4" key={index}>
                            <div
                              className={`card-shadow-warning  mb-3 card card-body  cardTwo card${blogddd.lead_status}`}
                            >
                              {/* <p
                                className={`ml-auto badge icon_right  card-brage`}
                              >
                                {blogddd.status_for_lead == "1" ? "assign" : ""}
                              </p> */}
                              <div className="cardtwodetails">
                                <div className="cardtwodetailsHead">
                                  <h6 style={{ color: "#1177a0" }}>
                                    {blogddd.customer_person_name}
                                    {/* <div
                                      className={`ml-auto badge icon_right  card-brage${blogddd.lead_status}`}
                                    >
                                      {blogddd.lead_status}
                                    </div> */}
                                  </h6>
                                  <div className="headIcons"></div>
                                </div>
                                <div
                                  className="cardtwodetailsHead"
                                  style={{ marginTop: "-9px" }}
                                >
                                  <span style={{ color: "#1177a0" }}>
                                    {blogddd.person_mobile_no} &nbsp;
                                  </span>
                                </div>
                                <div className="cardtwodetailsBody">
                                  <div className="cardtwodetailsBodyup">
                                    {Number(blogddd.total_amount) > 0.0 && (
                                      <h6>
                                        {rupess_icon} {blogddd.total_amount}
                                      </h6>
                                    )}

                                    <h6 style={{ color: "#1177a0" }}>
                                      {" "}
                                      {blogddd &&
                                      blogddd.data_menuselectlist &&
                                      blogddd.data_menuselectlist.length > 0
                                        ? blogddd.data_menuselectlist[0]
                                            .venue_name
                                        : ""}
                                    </h6>

                                    {/* {admin_profession === "0" && (
                                      <button
                                        type="button"
                                        tabIndex="0"
                                        className="deletbtnAdmin"
                                        onClick={() =>
                                          master_data_action(blogddd.primary_id)
                                        }
                                      >
                                        <img src={DeletIcon} alt="icon" />
                                      </button>
                                    )} */}
                                  </div>
                                  <div className="cardtwodetailsBodyup">
                                    <div className="cardtwodetailsBodylow">
                                      <p>
                                        {inputdateformateChange(
                                          blogddd.event_date
                                        )}
                                      </p>
                                      <p style={{ marginBottom: "1rem" }}>•</p>
                                    </div>
                                    {/* <p>{blogddd.event_timing_from}</p>
                                    <p>{blogddd.event_timing_to}</p> */}
                                    <h6 style={{ color: "#1177a0" }}>
                                      {" "}
                                      {blogddd &&
                                      blogddd.data_menuselectlist1 &&
                                      blogddd.data_menuselectlist1.length > 0
                                        ? blogddd.data_menuselectlist1[0]
                                            .category_master_name
                                        : ""}
                                    </h6>
                                  </div>
                                </div>
                                <div className="cardtwodetailsBodyFooter">
                                  {/* <p>
                                    {blogddd.status_for_lead === "0"
                                      ? blogddd.lead_source
                                      : blogddd.status_for_lead === "1" && (
                                          <Link
                                            onClick={() =>
                                              handleLinkClick(
                                                "/EnquiryDashboardConfirm/" +
                                                  blogddd.primary_id
                                              )
                                            }
                                            className="convert_booking"
                                          >
                                            Convert Into Booking
                                          </Link>
                                        )}
                                  </p> */}

                                  <h6
                                    style={{
                                      color: "#1177a0",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {/* {blogddd.status_for_lead === "1" ? ( */}
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(
                                          "/BookingStatusDetails/" +
                                            blogddd.primary_id
                                        )
                                      }
                                      className="any_ypdate"
                                    >
                                      Details
                                    </Link>
                                    {/* ) : null} */}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div class="tab-pane show" id="tab-eg7-2" role="tabpanel">
                      <div className=" row">
                        {BlogData.filter(
                          (cake) => cake.event_date > computeTodayDate()
                        ).map((blogddd, index) => (
                          <div className="col-md-4" key={index}>
                            <div
                              className={`card-shadow-warning  mb-3 card card-body  cardTwo card${blogddd.lead_status}`}
                            >
                              {/* <p
                                className={`ml-auto badge icon_right  card-brage`}
                              >
                                {blogddd.status_for_lead == "1" ? "assign" : ""}
                              </p> */}
                              <div className="cardtwodetails">
                                <div className="cardtwodetailsHead">
                                  <h6 style={{ color: "#1177a0" }}>
                                    {blogddd.customer_person_name}
                                    {/* <div
                                      className={`ml-auto badge icon_right  card-brage${blogddd.lead_status}`}
                                    >
                                      {blogddd.lead_status}
                                    </div> */}
                                  </h6>
                                  <div className="headIcons"></div>
                                </div>
                                <div
                                  className="cardtwodetailsHead"
                                  style={{ marginTop: "-9px" }}
                                >
                                  <span style={{ color: "#1177a0" }}>
                                    {blogddd.person_mobile_no} &nbsp;
                                  </span>
                                </div>
                                <div className="cardtwodetailsBody">
                                  <div className="cardtwodetailsBodyup">
                                    {Number(blogddd.total_amount) > 0.0 && (
                                      <h6>
                                        {rupess_icon} {blogddd.total_amount}
                                      </h6>
                                    )}

                                    <h6 style={{ color: "#1177a0" }}>
                                      {" "}
                                      {blogddd &&
                                      blogddd.data_menuselectlist &&
                                      blogddd.data_menuselectlist.length > 0
                                        ? blogddd.data_menuselectlist[0]
                                            .venue_name
                                        : ""}
                                    </h6>

                                    {/* {admin_profession === "0" && (
                                      <button
                                        type="button"
                                        tabIndex="0"
                                        className="deletbtnAdmin"
                                        onClick={() =>
                                          master_data_action(blogddd.primary_id)
                                        }
                                      >
                                        <img src={DeletIcon} alt="icon" />
                                      </button>
                                    )} */}
                                  </div>
                                  <div className="cardtwodetailsBodyup">
                                    <div className="cardtwodetailsBodylow">
                                      <p>
                                        {inputdateformateChange(
                                          blogddd.event_date
                                        )}
                                      </p>
                                      <p style={{ marginBottom: "1rem" }}>•</p>
                                    </div>
                                    {/* <p>{blogddd.event_timing_from}</p>
                                    <p>{blogddd.event_timing_to}</p> */}
                                    <h6 style={{ color: "#1177a0" }}>
                                      {" "}
                                      {blogddd &&
                                      blogddd.data_menuselectlist1 &&
                                      blogddd.data_menuselectlist1.length > 0
                                        ? blogddd.data_menuselectlist1[0]
                                            .category_master_name
                                        : ""}
                                    </h6>
                                  </div>
                                </div>
                                <div className="cardtwodetailsBodyFooter">
                                  {/* <p>
                                    {blogddd.status_for_lead === "0"
                                      ? blogddd.lead_source
                                      : blogddd.status_for_lead === "1" && (
                                          <Link
                                            onClick={() =>
                                              handleLinkClick(
                                                "/EnquiryDashboardConfirm/" +
                                                  blogddd.primary_id
                                              )
                                            }
                                            className="convert_booking"
                                          >
                                            Convert Into Booking
                                          </Link>
                                        )}
                                  </p> */}

                                  <h6
                                    style={{
                                      color: "#1177a0",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {/* {blogddd.status_for_lead === "1" ? ( */}
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(
                                          "/BookingStatusDetails/" +
                                            blogddd.primary_id
                                        )
                                      }
                                      className="any_ypdate"
                                    >
                                      Details
                                    </Link>
                                    {/* ) : null} */}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div class="tab-pane show" id="tab-eg7-1" role="tabpanel">
                      <div className=" row">
                        {BlogData.filter(
                          (cake) => cake.event_date < computeTodayDate()
                        ).map((blogddd, index) => (
                          <div className="col-md-4" key={index}>
                            <div
                              className={`card-shadow-warning  mb-3 card card-body  cardTwo card${blogddd.lead_status}`}
                            >
                              {/* <p
                                className={`ml-auto badge icon_right  card-brage`}
                              >
                                {blogddd.status_for_lead == "1" ? "assign" : ""}
                              </p> */}
                              <div className="cardtwodetails">
                                <div className="cardtwodetailsHead">
                                  <h6 style={{ color: "#1177a0" }}>
                                    {blogddd.customer_person_name}
                                    {/* <div
                                      className={`ml-auto badge icon_right  card-brage${blogddd.lead_status}`}
                                    >
                                      {blogddd.lead_status}
                                    </div> */}
                                  </h6>
                                  <div className="headIcons"></div>
                                </div>
                                <div
                                  className="cardtwodetailsHead"
                                  style={{ marginTop: "-9px" }}
                                >
                                  <span style={{ color: "#1177a0" }}>
                                    {blogddd.person_mobile_no} &nbsp;
                                  </span>
                                </div>
                                <div className="cardtwodetailsBody">
                                  <div className="cardtwodetailsBodyup">
                                    {Number(blogddd.total_amount) > 0.0 && (
                                      <h6>
                                        {rupess_icon} {blogddd.total_amount}
                                      </h6>
                                    )}

                                    <h6 style={{ color: "#1177a0" }}>
                                      {" "}
                                      {blogddd &&
                                      blogddd.data_menuselectlist &&
                                      blogddd.data_menuselectlist.length > 0
                                        ? blogddd.data_menuselectlist[0]
                                            .venue_name
                                        : ""}
                                    </h6>

                                    {/* {admin_profession === "0" && (
                                      <button
                                        type="button"
                                        tabIndex="0"
                                        className="deletbtnAdmin"
                                        onClick={() =>
                                          master_data_action(blogddd.primary_id)
                                        }
                                      >
                                        <img src={DeletIcon} alt="icon" />
                                      </button>
                                    )} */}
                                  </div>
                                  <div className="cardtwodetailsBodyup">
                                    <div className="cardtwodetailsBodylow">
                                      <p>
                                        {inputdateformateChange(
                                          blogddd.event_date
                                        )}
                                      </p>
                                      <p style={{ marginBottom: "1rem" }}>•</p>
                                    </div>
                                    {/* <p>{blogddd.event_timing_from}</p>
                                    <p>{blogddd.event_timing_to}</p> */}
                                    <h6 style={{ color: "#1177a0" }}>
                                      {" "}
                                      {blogddd &&
                                      blogddd.data_menuselectlist1 &&
                                      blogddd.data_menuselectlist1.length > 0
                                        ? blogddd.data_menuselectlist1[0]
                                            .category_master_name
                                        : ""}
                                    </h6>
                                  </div>
                                </div>
                                <div className="cardtwodetailsBodyFooter">
                                  {/* <p>
                                    {blogddd.status_for_lead === "0"
                                      ? blogddd.lead_source
                                      : blogddd.status_for_lead === "1" && (
                                          <Link
                                            onClick={() =>
                                              handleLinkClick(
                                                "/EnquiryDashboardConfirm/" +
                                                  blogddd.primary_id
                                              )
                                            }
                                            className="convert_booking"
                                          >
                                            Convert Into Booking
                                          </Link>
                                        )}
                                  </p> */}

                                  <h6
                                    style={{
                                      color: "#1177a0",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {/* {blogddd.status_for_lead === "1" ? ( */}
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(
                                          "/BookingStatusDetails/" +
                                            blogddd.primary_id
                                        )
                                      }
                                      className="any_ypdate"
                                    >
                                      Details
                                    </Link>
                                    {/* ) : null} */}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default BookingStatusView;
