import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  update_assign_staff,
  get_all_main_lead,
} from "../../ServiceConnection/serviceconnection.js";

import {
  check_vaild_save,
  combiled_form_data,
  formatDateString,
  handleSuccessSession,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
function EnquiryDashboardViewDetails() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editBlogData, seteditBlogData] = useState([]);
  const [HalllistData, seteditHalllistData] = useState([]);
  const [MenulistData, setMenulistData] = useState([]);
  const [ProcesslistData, setProcesslistData] = useState([]);
  // const [data_eventtiming, setdata_eventtiming] = useState([]);
  const [data_admin_staff, setdata_admin_staff] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [StafflistData, setStafflistData] = useState([]);
  const [RUPESSICON, setRUPESSICON] = useState("₹");
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedVendorId = retrieveData("vendor_id");
  const admin_vender = retrieveData("admin_profession");
  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "4";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    console.log(call_id);
    fd.append("admin_id", retrievedAdminId);
    fd.append("vendor_id", retrievedVendorId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("admin_vender", admin_vender);
    fd.append("select_resource", "All");
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_main_lead, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_venue.length > 0) {
            seteditBlogData(Response.data.message.data_venue[0]);

            seteditHalllistData(Response.data.message.data_venue[0].venue_data);
            setMenulistData(
              Response.data.message.data_venue[0].category_data[0]
            );
            setRUPESSICON(Response.data.message.rupess_icon);
            setdata_admin_staff(
              Response.data.message.data_venue[0].venue_owner_data[0]
            );
            setStafflistData(Response.data.message.data_admin_staff);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  // console.log(data_admin_staff);
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("main_id", editBlogData.primary_id);
      fd_from.append("status_for_lead", "1");
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(
              Response.data.message,
              "/enquiryAssignDashboardView"
            );
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Enquiry Dashboard
                    <div className="page-title-subheading">
                      Enquiry Progress and Update
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Enquiry Process</h5>
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Enquiry Start Date :-
                        <span className="float_right_show">
                          {formatDateString(editBlogData.lead_start_date) || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Venue Name :-
                        <span className="float_right_show">
                          {HalllistData.map(
                            (blogdddddd, index) => (
                              // editBlogData.lead_for_event_hall ===
                              // blogdddddd.hall_name && (
                              <span key={index}>{blogdddddd.venue_name}</span>
                            )
                            // )
                          )}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Enquiry Type :-
                        <span className="float_right_show">
                          {editBlogData.lead_status || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Mobile No :-
                        <span className="float_right_show">
                          {editBlogData.lead_person_mobile_no || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Venue Owner :-
                        <span className="float_right_show">
                          {data_admin_staff && data_admin_staff.admin_name}
                          {/* {data_admin_staff.admin_name} */}
                        </span>
                      </button>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Booking Type :-
                        <span className="float_right_show">
                          {editBlogData.lead_type || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Person Name :-
                        <span className="float_right_show">
                          {editBlogData.lead_person_name || ""}
                        </span>
                      </button>
                      <button className="list-group-item-action list-group-item">
                        Company Name :-
                        <span className="float_right_show">
                          {editBlogData.lead_person_company_name || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Email ID :-
                        <span className="float_right_show">
                          {editBlogData.lead_person_email_id || ""}
                        </span>
                      </button>
                    </ul>
                  </div>

                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Event Date :-
                        <span className="float_right_show">
                          {formatDateString(editBlogData.lead_event_date) || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        No of Guest :-
                        <span className="float_right_show">
                          {editBlogData.lead_event_no_of_person_come || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Referred Person Name :-
                        <span className="float_right_show">
                          {editBlogData.referred_by_person_name || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Referred Mobile No :-
                        <span className="float_right_show">
                          {editBlogData.referred_by_person_no || ""}
                        </span>
                      </button>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <div className=" list-group-item-action list-group-item">
                        {/* Event Name & Time :- */}
                        {/* <span>
                          {data_eventtiming.map((data_get) => {
                            if (
                              Number(data_get.primary_id) ===
                              Number(editBlogData.lead_for_eventtiming_id)
                            ) {
                              return (
                                <span
                                  className="float_right_show"
                                  key={data_get.primary_id}
                                >
                                  {editBlogData.event_list_name}{" "}
                                  {data_get.timing_name} ({data_get.timing_from}{" "}
                                  - {data_get.timing_to})
                                </span>
                              );
                            }
                            return null; // You may want to handle the case when there is no match
                          })}
                        </span> */}

                        <div className=" d-flex justify-content-between">
                          <p>Event Type:</p>
                          <p
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {MenulistData.category_master_name}
                          </p>
                        </div>
                        <div className=" d-flex justify-content-between">
                          <p>Event Timing:</p>
                          <p
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            From <span>{editBlogData.event_timing_from} </span>{" "}
                            to <span>{editBlogData.event_timing_to} </span>
                          </p>
                        </div>
                      </div>
                    </ul>
                  </div>
                  <div className="col-md-12 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Full Address :-
                        <span className="float_right_show">
                          {editBlogData.lead_person_address && (
                            <span>{editBlogData.lead_person_address}, </span>
                          )}
                          {editBlogData.lead_person_city && (
                            <span>{editBlogData.lead_person_city}, </span>
                          )}
                          {editBlogData.lead_person_country && (
                            <span>{editBlogData.lead_person_country}</span>
                          )}
                        </span>
                      </button>
                      {editBlogData.special_requirement &&
                        editBlogData.special_requirement.length > 2 && (
                          <button className=" list-group-item-action list-group-item">
                            Special Requirement :-
                            <span className="float_right_show">
                              {editBlogData.special_requirement || ""}
                            </span>
                          </button>
                        )}
                    </ul>
                  </div>
                  {/* {MenulistData.length > 0 && (
                    <div className="col-md-12 mb-3">
                      <h5 className="card-title">Menu List</h5>
                      <ul className="list-group">
                        {MenulistData.map((blogdddddd, index) => (
                          <button
                            className=" list-group-item-action list-group-item"
                            key={index}
                          >
                            {blogdddddd.menu_name}
                            <span className="float_right_show">
                              {RUPESSICON}{" "}
                              {blogdddddd.menu_discount_price > 0 ? (
                                <span>{blogdddddd.menu_discount_price}</span>
                              ) : (
                                <span>{blogdddddd.menu_price}</span>
                              )}
                            </span>
                          </button>
                        ))}
                      </ul>
                    </div>
                  )} */}
                  {/* {ProcesslistData.length > 0 && (
                    <div className="col-md-12 mb-3">
                      <h5 className="card-title">Enquiry Progress</h5>
                      <ul className="list-group">
                        {ProcesslistData.map((blogdddddd, index) => (
                          <button
                            className=" list-group-item-action list-group-item"
                            key={index}
                          >
                            <div className="row">
                              <div className="col-md-4 ">
                                {" "}
                                {formatDateString(blogdddddd.again_ask_date)}
                              </div>
                              <div className="col-md-2 ">
                                {" "}
                                <span className="min_center">
                                  {blogdddddd.meeting_minutes} Min{" "}
                                </span>
                              </div>
                              <div className="col-md-6 ">
                                {" "}
                                <span className="float_right_show">
                                  {blogdddddd.remark}
                                </span>
                              </div>
                            </div>
                          </button>
                        ))}
                      </ul>
                    </div>
                  )} */}
                </div>
                {editBlogData.status_for_lead === "0" && (
                  <form className="needs-validation" id="CarrerformData">
                    <div className="form-row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom01">
                          Assign To Staff<span className="red_show">*</span>
                        </label>
                        <select
                          className="form-control trio_mandatory"
                          name="lead_assign_to_staff"
                          id="lead_assign_to_staff"
                        >
                          <option value="" disabled selected hidden>
                            Select Assign Staff
                          </option>
                          <option
                            value={retrievedAdminId}
                            selected={
                              editBlogData.lead_assign_to_staff ===
                              retrievedAdminId
                            }
                          >
                            Self Assign
                          </option>
                          {StafflistData.map((blogdddddd, index) => (
                            <option
                              key={index}
                              value={blogdddddd.primary_id}
                              selected={
                                editBlogData.lead_assign_to_staff ===
                                blogdddddd.primary_id
                              }
                            >
                              {blogdddddd.admin_name}({" "}
                              {blogdddddd.admin_mobile_no}){" "}
                              {blogdddddd.admin_vendor == "0"
                                ? "Super Admin"
                                : ""}
                              {blogdddddd.admin_vendor == "2"
                                ? "Venue Owner"
                                : ""}
                              {blogdddddd.admin_vendor == "1" ? "Staff" : ""}
                            </option>
                          ))}
                        </select>

                        <span className="condition_error"></span>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom01">
                          Enquiry Type<span className="red_show">*</span>
                        </label>
                        <select
                          className="form-control trio_mandatory"
                          name="lead_status"
                          id="lead_status"
                        >
                          <option value="" disabled selected hidden>
                            Select Enquiry Type
                          </option>
                          <option
                            value="Hot"
                            selected={editBlogData.lead_status === "Hot"}
                          >
                            Hot
                          </option>
                          <option
                            value="Cold"
                            selected={editBlogData.lead_status === "Cold"}
                          >
                            Cold
                          </option>
                          <option
                            value="Warm"
                            selected={editBlogData.lead_status === "Warm"}
                          >
                            Warm
                          </option>
                        </select>

                        <span className="condition_error"></span>
                      </div>
                      <div className="col-md-4 mb-3">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() =>
                            handleSaveChangesdynamic(
                              "CarrerformData",
                              update_assign_staff
                            )
                          }
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default EnquiryDashboardViewDetails;
