//
import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  change_active_status,
  delete_update_data,
  get_all_venue,
  APL_LINK,
} from "../../ServiceConnection/serviceconnection.js";
import {
  fnExcelReport,
  handleLinkClick,
  handleConfimDeleteClick,
  formatDateString,
  handleError,
  handleSuccess,
} from "../../CommonJquery/CommonJquery.js";

import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { Link } from "react-router-dom";
function VenueDeshboardView() {
  const retrievedAdminProfession = retrieveData("admin_profession");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [BlogData, setsBlogData] = useState([]);
  const [BlogImageData, setsBlogImageData] = useState([]);
  const [SingleBlogData, setsSingleBlogData] = useState([]);
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedvendor_id = retrieveData("vendor_id");

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

  // Initialize end date as today
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(formattedSevenDaysAgo);
  const [endDate, setEndDate] = useState(formattedToday);
  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    const call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("vendor_id", retrievedvendor_id);
    fd.append("admin_vender", retrievedAdminProfession);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_venue, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setsBlogData(Response.data.message);
          setsBlogImageData(Response.data.message);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action = async (call_id) => {
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("id_for_delete", call_id);
      fd.append("flag_for", "5");
      fd.append("for_status_final", "d");
      console.log(call_id);
      await server_post_data(delete_update_data, fd)
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log(Response.data);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccess(Response.data.message);
            master_data_get(startDate, endDate, "1", "0");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handlestatusclick = async (data) => {
    const fd = new FormData();
    fd.append("call_id", data.primary_id);
    fd.append("status", data.active_status);
    fd.append("flag", "1");
    await server_post_data(change_active_status, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
          master_data_get(startDate, endDate, "1", "0");
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  // console.log(APL_LINK + "/assets/" + BlogData[0].venue_images);
  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Venue Management
                    <div className="page-title-subheading">
                      Where You Can Manage Your Venue
                    </div>
                  </div>
                </div>
                <div className="page-title-actions add_funtion_call">
                  {retrievedAdminProfession === "0" ? (
                    <Link onClick={() => handleLinkClick("/VenueDeshboardAdd")}>
                      <button
                        type="button"
                        className="btn-shadow mr-3 btn btn-dark"
                      >
                        <i className="fa fa-plus">&nbsp;Add Venue</i>
                      </button>
                    </Link>
                  ) : (
                    ""
                  )}
                  {retrievedAdminProfession === "0" ? (
                    <>
                      <Link
                        onClick={() =>
                          handleLinkClick("/CategoryDeshboardView")
                        }
                      >
                        <button
                          type="button"
                          className="btn-shadow mr-3 btn btn-dark"
                        >
                          <i className="fa fa-plus">&nbsp;Category</i>
                        </button>
                      </Link>
                      <Link
                        onClick={() =>
                          handleLinkClick("/AmenitiesDeshboardView")
                        }
                      >
                        <button
                          type="button"
                          className="btn-shadow mr-3 btn btn-dark"
                        >
                          <i className="fa fa-plus">&nbsp;Amenities</i>
                        </button>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="main-card mb-3 card">
                  <div className="card-header card-header-view">
                    <div className="btn-actions-pane-left row">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            <b>Start Date</b>
                          </label>
                          <input
                            type="date"
                            className="form-control trio_search_ajax trio_mandatory_view"
                            name="start_date"
                            id="start_date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            <b>End Date</b>
                          </label>
                          <input
                            type="date"
                            className="form-control trio_search_ajax trio_mandatory_view"
                            name="end_date"
                            id="end_date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-sm-1">
                        <div className="form-group">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <b>&nbsp;</b>
                            </label>
                            <button
                              type="button"
                              className="form-control  btn btn-primary btn-sm"
                              name="end_date"
                              onClick={() => search_data()}
                            >
                              <i className="ace-icon fa fa-search icon-on-right bigger-110"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="btn-actions-pane-right btn-actions-pane-right-float ">
                        <div role="group" className="btn-group-sm btn-group">
                          <button
                            className="active btn btn-focus"
                            onClick={fnExcelReport}
                          >
                            Download Excel
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table
                      className="align-middle mb-0 table table-borderless table-striped table-hover"
                      id="dynamic-table1"
                    >
                      <thead>
                        <tr>
                          <th className="text-center">S.No</th>
                          <th className="text-center">Name</th>
                          <th className="text-center">Images</th>
                          <th className="text-center">City</th>
                          <th className="text-center">Entry Date</th>
                          <th className="text-center">Details</th>
                          <th className="text-center">Status</th>
                          {retrievedAdminProfession === "0" ? (
                            <>
                              <th className="text-center">Action</th>
                            </>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {BlogData.map((blogddd, index) => (
                          <tr key={index}>
                            <td className="text-center text-muted">
                              {index + 1}
                            </td>

                            <td className="text-center">
                              {blogddd.venue_name}
                            </td>
                            <td className="text-center">
                              <img
                                className="width_height_50px"
                                src={
                                  APL_LINK + "/assets/" + blogddd.venue_images
                                }
                              ></img>
                            </td>
                            <td className="text-center">{blogddd.city}</td>
                            <td className="text-center">
                              {formatDateString(blogddd.entry_date)}
                            </td>
                            <td className="text-center">
                              <button
                                type="button"
                                className="btn mr-2 mb-2 btn-primary"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setsSingleBlogData(blogddd)}
                              >
                                See More
                              </button>
                            </td>

                            <td className="text-center">
                              <button
                                type="button"
                                className={`btn mr-2 mb-2 ${
                                  blogddd.active_status === 0
                                    ? "btn-danger"
                                    : "btn-success"
                                }`}
                                data-toggle="modal"
                                data-target="#exampleModal1"
                                onClick={() => setsSingleBlogData(blogddd)}
                              >
                                {blogddd.active_status === 0
                                  ? "Deactive"
                                  : "Active"}
                              </button>
                            </td>
                            {retrievedAdminProfession === "0" ? (
                              <>
                                <td className="text-center">
                                  <div className="dropdown action_function_call">
                                    <button
                                      type="button"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      data-toggle="dropdown"
                                      className="mb-4 mr-2 dropdown-toggle btn btn-outline-secondary "
                                    >
                                      Action
                                    </button>
                                    <div
                                      tabIndex="-1"
                                      role="menu"
                                      aria-hidden="true"
                                      className="dropdown-menu"
                                      x-placement="top-start"
                                    >
                                      <Link
                                        onClick={() =>
                                          handleLinkClick(
                                            "/VenueDeshboardEdit/" +
                                              blogddd.primary_id
                                          )
                                        }
                                      >
                                        <button
                                          type="button"
                                          tabIndex="0"
                                          className="dropdown-item"
                                        >
                                          Edit
                                        </button>
                                      </Link>
                                      <Link
                                        onClick={() =>
                                          handleLinkClick(
                                            "/VenueDeshboardEdit/" +
                                              blogddd.primary_id +
                                              "/PackageVenueView"
                                          )
                                        }
                                      >
                                        <button
                                          type="button"
                                          tabIndex="0"
                                          className="dropdown-item"
                                        >
                                          Packages
                                        </button>
                                      </Link>
                                      <Link
                                        onClick={() =>
                                          handleLinkClick(
                                            "/VenueDeshboardEdit/" +
                                              blogddd.primary_id +
                                              "/ExtraAddonVenueView"
                                          )
                                        }
                                      >
                                        <button
                                          type="button"
                                          tabIndex="0"
                                          className="dropdown-item"
                                        >
                                          Extra Add On
                                        </button>
                                      </Link>
                                      <Link
                                        onClick={() =>
                                          handleLinkClick(
                                            "/VenueDeshboardEdit/" +
                                              blogddd.primary_id +
                                              "/CategoryVenueView"
                                          )
                                        }
                                      >
                                        <button
                                          type="button"
                                          tabIndex="0"
                                          className="dropdown-item"
                                        >
                                          Category
                                        </button>
                                      </Link>
                                      <Link
                                        onClick={() =>
                                          handleLinkClick(
                                            "/VenueDeshboardEdit/" +
                                              blogddd.primary_id +
                                              "/AmenitiesVenueView"
                                          )
                                        }
                                      >
                                        <button
                                          type="button"
                                          tabIndex="0"
                                          className="dropdown-item"
                                        >
                                          Amenities
                                        </button>
                                      </Link>
                                      <Link
                                        onClick={() =>
                                          handleLinkClick(
                                            "/VenueDeshboardEdit/" +
                                              blogddd.primary_id +
                                              "/ImageVenueView"
                                          )
                                        }
                                      >
                                        <button
                                          type="button"
                                          tabIndex="0"
                                          className="dropdown-item"
                                        >
                                          Images
                                        </button>
                                      </Link>
                                      {/* <Link
                                        onClick={() =>
                                          handleLinkClick(
                                            "/VenueDeshboardEdit/" +
                                              blogddd.primary_id +
                                              "/DayWiseRateView"
                                          )
                                        }
                                      >
                                        <button
                                          type="button"
                                          tabIndex="0"
                                          className="dropdown-item"
                                        >
                                          Day Wise Rate
                                        </button>
                                      </Link> */}
                                      {retrievedAdminProfession == "0" ? (
                                        <Link
                                          onClick={() =>
                                            master_data_action(
                                              blogddd.primary_id
                                            )
                                          }
                                        >
                                          <button
                                            type="button"
                                            tabIndex="0"
                                            className="dropdown-item"
                                          >
                                            Delete
                                          </button>
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Venue Name:- {SingleBlogData.venue_name}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body ">
              <p className="mb-0">
                <b>Full Address</b>:-{SingleBlogData.type_address}
              </p>
              <p className="mb-0">
                <b>Landmark</b>:-{SingleBlogData.landmark}
              </p>
              <p className="mb-0">
                <b>City</b>:-{SingleBlogData.city}
              </p>
              <p className="mb-0">
                <b>State</b>:-{SingleBlogData.state}
              </p>
              <p className="mb-0">
                <b>Country</b>:-{SingleBlogData.country}
              </p>
              <p className="mb-0">
                <b>Zipcode</b>:-{SingleBlogData.pincode}
              </p>
              <p className="mb-0">
                <b>Latitude</b>:-{SingleBlogData.venue_latitude}
              </p>
              <p className="mb-0">
                <b>Longitude</b>:-{SingleBlogData.venue_longitude}
              </p>
              <p className="mb-0">
                <b>Guests Capacity</b>:-{SingleBlogData.guests_capacity}
              </p>
              <p className="mb-0">
                <b>Description</b>:-{SingleBlogData.description}
              </p>
              <p className="mb-0">
                <b>Note</b>:-{SingleBlogData.extra_description}
              </p>
              <p className="mb-0">
                <b>Price Per Day</b>:-{SingleBlogData.price_per_day}
              </p>
              <p className="mb-0">
                <b>Price Per Hour</b>:-{SingleBlogData.price_per_hour}
              </p>
              <p className="mb-0">
                <b>Discount</b>:-{SingleBlogData.discount_per}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">
                Venue Name:- {SingleBlogData.venue_name}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to{" "}
                {SingleBlogData.active_status === 1 ? "Deactive" : "Active"} the
                venue!"
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={() => handlestatusclick(SingleBlogData)}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VenueDeshboardView;
