import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import AdminSidebar from "./AdminSidebar.js";
import AdminHeader from "./AdminHeader.js";
import { useParams, Link } from "react-router-dom";
import {
  server_post_data,
  add_multi_images,
} from "../../ServiceConnection/serviceconnection.js";
import {
  handleSuccessSession,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import crossmodalImg from "../../Assests/images/crossmodalImg.png";
import imgUploadIcon from "../../Assests/images/imgUploadIcon.svg";

const ImageVenueAdd = () => {
  let { id } = useParams();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [files, setFiles] = useState([]);

  // const onDrop = useCallback((acceptedFiles) => {
  //   setFiles((prevFiles) => [
  //     ...prevFiles,
  //     ...acceptedFiles.map((file) =>
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //       })
  //     ),
  //   ]);
  // }, []);

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: "image/*",
  //   multiple: true, // Allow multiple file selection
  //   onDrop,
  // });

  // const onDrop = useCallback((acceptedFiles) => {
  //   const filteredFiles = acceptedFiles.filter(
  //     (file) => file.size <= 500 * 1024
  //   ); // 500KB in bytes
  //   if (filteredFiles.length < acceptedFiles.length) {
  //     handleError(
  //       "Some files were not accepted because they exceed the size limit of 500KB."
  //     );
  //   }

  //   setFiles((prevFiles) => [
  //     ...prevFiles,
  //     ...filteredFiles.map((file) =>
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //       })
  //     ),
  //   ]);
  // }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const filteredFiles = acceptedFiles.filter(
      (file) => file.size >= 500 * 1024 && file.size <= 3 * 1024 * 1024
    ); // 500KB in bytes

    if (filteredFiles.length < acceptedFiles.length) {
      handleError(
        "Some files were not accepted because they do not meet the size criteria (500KB - 3MB)."
      );
    }

    setFiles((prevFiles) => {
      const totalFiles = prevFiles.length + filteredFiles.length;
      if (totalFiles > 5) {
        handleError("You can only upload up to 5 images.");
        return prevFiles;
      }

      return [
        ...prevFiles,
        ...filteredFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ];
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: true, // Allow multiple file selection
    onDrop,
  });

  const uploadFiles = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("venue_id", id);
    await server_post_data(add_multi_images, formData)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccessSession(
            Response.data.message,
            `/VenueDeshboardEdit/${id}/ImageVenueView`
          );
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  const removeImage = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  const thumbs = files.map((file, index) => (
    <div key={file.name} className="thumb">
      <img src={file.preview} alt={file.name} className="thumb-img" />
      <img
        src={crossmodalImg}
        alt="Remove"
        className="remove-icon"
        onClick={() => removeImage(index)}
      />
    </div>
  ));

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />
      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Venue Image Management &gt; Image
                    <div className="page-title-subheading">
                      Where You Can Check Venue Image
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="main-card mb-3 card">
                  <div className="card-header card-header-view">
                    <div className="btn-actions-pane-left row"></div>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input
                        {...getInputProps({ multiple: true })}
                        className="input-file"
                      />
                      {files.length === 0 ? (
                        <p>
                          <img
                            className="upload"
                            src={imgUploadIcon}
                            alt="Upload"
                          ></img>
                          <label className="upladImgLabel">Upload Image</label>
                        </p>
                      ) : (
                        <p>Choosed Images</p>
                      )}
                    </div>
                    <aside className="thumbs-container">{thumbs}</aside>
                    <span style={{ color: "red", fontWeight: "600" }}>
                      (size 1000*650 upto 500kb )
                    </span>
                    <div className="uploadImgs">
                      {" "}
                      <button onClick={uploadFiles}>Upload Images</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageVenueAdd;
