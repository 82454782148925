import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  delete_update_data,
  get_all_main_lead,
} from "../../ServiceConnection/serviceconnection.js";
import {
  handleLinkClick,
  inputdateformateChange,
  handleConfimDeleteClick,
  computeTodayDate,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { Link } from "react-router-dom";
import DeletIcon from "../../Assests/images/delete.png";
function EnquiryDashboardView() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [BlogData, setsBlogData] = useState([]);
  const [rupess_icon, setrupess_icon] = useState("");
  const retrievedAdminId = retrieveData("staff_id");
  const admin_profession = retrieveData("admin_profession");
  const venue_id = retrieveData("Venue_id");
  const vendor_id = retrieveData("vendor_id");
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

  // Initialize end date as today
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(formattedSevenDaysAgo);
  const [endDate, setEndDate] = useState(formattedToday);
  const [SelectResource, setSelectResource] = useState("All");
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(startDate, endDate, SelectResource, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, SelectResource, flag, call_id);
  };
  const master_data_get = async (
    start_date,
    end_date,
    select_resource,
    flag,
    call_id
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("vendor_id", vendor_id);
    fd.append("admin_vender", admin_profession);
    fd.append("start_date", start_date);
    fd.append("select_resource", select_resource);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    // fd.append("venue_id", venue_id);
    fd.append("call_id", call_id);
    await server_post_data(get_all_main_lead, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data.message);
          setsBlogData(Response.data.message.data_main_lead);
          setrupess_icon(Response.data.message.rupess_icon);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action = async (call_id) => {
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("id_for_delete", call_id);
      fd.append("flag_for", "14");
      fd.append("for_status_final", "d");
      await server_post_data(delete_update_data, fd)
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log(Response.data);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get(startDate, endDate, "", "1", "0");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    View Enquiry Dashboard
                    <div className="page-title-subheading">
                      Where You Can Check Enquiry
                    </div>
                  </div>
                </div>
                <div className="page-title-actions add_funtion_call">
                  <Link onClick={() => handleLinkClick("/enquiryDashboardAdd")}>
                    <button
                      type="button"
                      className="btn-shadow mr-3 btn btn-dark"
                    >
                      <i className="fa fa-plus">&nbsp;Add Enquiry</i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card-header card-header-view">
                  <div className="btn-actions-pane-left row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          <b>Start Date</b>
                        </label>
                        <input
                          type="date"
                          className="form-control trio_search_ajax trio_mandatory_view"
                          name="start_date"
                          id="start_date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          <b>End Date</b>
                        </label>
                        <input
                          type="date"
                          className="form-control trio_search_ajax trio_mandatory_view"
                          name="end_date"
                          id="end_date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Source From<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_source"
                        id="lead_source"
                        value={SelectResource}
                        onChange={(e) => setSelectResource(e.target.value)}
                      >
                        <option value="All">All</option>
                        <option value="Walk-In">Walk-In</option>
                        <option value="Facebook">Facebook</option>
                        <option value="JustDial">JustDial</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Call">Call</option>
                        <option value="Website">Website</option>
                      </select>

                      <span className="condition_error"></span>
                    </div> */}
                    <div className="col-sm-1">
                      <div className="form-group">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            <b>&nbsp;</b>
                          </label>
                          <button
                            type="button"
                            className="form-control  btn btn-primary btn-sm"
                            name="end_date"
                            onClick={() => search_data()}
                          >
                            <i className="ace-icon fa fa-search icon-on-right bigger-110"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {BlogData.map((blogddd, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    className={`card-shadow-warning  mb-3 card card-body  cardTwo card${blogddd.lead_status}`}
                  >
                    {/* <p className={`ml-auto badge icon_right  card-brage`}>
                      {blogddd.status_for_lead == "0" ? "Request" : ""}
                    </p> */}
                    <div className="cardtwodetails">
                      <div className="cardtwodetailsHead">
                        <h6
                          style={{ color: "#1177a0" }}
                          className="d-flex justify-content-between"
                        >
                          {blogddd.lead_person_name}
                          {admin_profession === "0" && (
                            <button
                              type="button"
                              tabIndex="0"
                              className="deletbtnAdmin"
                              onClick={() =>
                                master_data_action(blogddd.primary_id)
                              }
                            >
                              <img src={DeletIcon} alt="icon" />
                            </button>
                          )}
                        </h6>
                        <div className="headIcons"></div>
                      </div>
                      <div
                        className="cardtwodetailsHead"
                        style={{ marginTop: "-9px" }}
                      >
                        <span style={{ color: "#1177a0" }}>
                          {blogddd.lead_person_mobile_no} &nbsp;
                        </span>
                      </div>
                      <div className="cardtwodetailsBody">
                        <div className="cardtwodetailsBodyup">
                          {Number(blogddd.lead_total_amount) > 0.0 && (
                            <h6>
                              {rupess_icon} {blogddd.lead_total_amount}
                            </h6>
                          )}

                          <h6 style={{ color: "#1177a0" }}>
                            {" "}
                            {blogddd.lead_for_event_hall}
                          </h6>
                          <div
                            className={`ml-auto badge icon_right  card-brage${blogddd.lead_status}`}
                          >
                            {blogddd.lead_status}
                          </div>
                        </div>
                        <div className="cardtwodetailsBodylow">
                          <p>{inputdateformateChange(blogddd.entry_date)}</p>
                          <p style={{ marginBottom: "1rem" }}>•</p>
                          <p>{blogddd.lead_for_eventtiming_name}</p>
                          <p>{blogddd.lead_source}</p>
                        </div>
                      </div>
                      <div className="cardtwodetailsBodyFooter">
                        <p>
                          {
                            // blogddd.status_for_lead === "0"
                            // ? blogddd.lead_source
                            // : blogddd.status_for_lead === "1" && (
                            <Link
                              onClick={() =>
                                handleLinkClick(
                                  "/EnquiryDashboardConfirm/" +
                                    blogddd.primary_id
                                )
                              }
                              className="convert_booking"
                            >
                              Convert Into Booking
                            </Link>
                            // )
                          }
                        </p>
                        {admin_profession === "0" ||
                        admin_profession === "2" ? (
                          <h6 style={{ color: "#000", cursor: "pointer" }}>
                            {blogddd.status_for_lead === "0" ? (
                              <Link
                                onClick={() =>
                                  handleLinkClick(
                                    "/enquiryDashboardViewDetails/" +
                                      blogddd.primary_id
                                  )
                                }
                                className="convert_booking"
                              >
                                View Details
                              </Link>
                            ) : null}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default EnquiryDashboardView;
