import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  save_assign_rights,
  get_all_staff,
  get_all_admin_staff,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { Label } from "reactstrap";
function UserMasterStaffDashboardEdit() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editBlogData, seteditBlogData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = retrieveData("staff_id");

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    if (parts.length !== 1) {
      const start_date = "";
      const end_date = "";
      const flag = "4";
      const call_id = parts[1];
      master_data_get(start_date, end_date, flag, call_id);
    }
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    // await server_post_data(get_all_staff, fd)
    await server_post_data(get_all_admin_staff, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // seteditBlogData(Response.data.message.data_staff);
          seteditBlogData(Response.data.message.data_admin_staff);

          // const serverCheckboxData = Response.data.message.data_staff_master;
          const serverCheckboxData =
            Response.data.message.data_staff_user_master;

          // Update checkboxValues state with the received data using a loop
          setCheckboxValues((prevValues) => {
            let updatedValues = { ...prevValues };
            for (const key in serverCheckboxData) {
              const newValue = {
                ...updatedValues[key],
                user_fun_id: Number(serverCheckboxData[key].user_fun_id),
              };
              updatedValues[key] = newValue;
            }
            return updatedValues;
          });

          setEditorDatMainID(call_id);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("admin_id", retrievedAdminId);
      fd_from.append("main_id", editorDataMainID);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/staffDashboardView");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const [checkboxValues, setCheckboxValues] = useState({});

  // Function to handle individual checkbox change
  const handleCheckboxChange = (rightsId, masterName) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [rightsId]: !prevValues[rightsId],
      [`${rightsId}_${masterName}`]: !prevValues[`${rightsId}_${masterName}`],
      [`${rightsId}_other_${masterName}`]:
        !prevValues[`${rightsId}_other_${masterName}`],
    }));
  };

  // Function to handle master checkbox change
  const handleMasterCheckboxChange = (masterName) => {
    const rightsIds = document.querySelectorAll(`.master_${masterName}`);
    rightsIds.forEach((rightsId) => {
      handleCheckboxChange(rightsId.id, masterName);
    });
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    User Master Dashboard
                    <div className="page-title-subheading">
                      Add and Edit User Master
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Add/Edit User Master</h5>
                <form className="needs-validation" id="CarrerformData">
                  {/* <div className="form-row"> */}
                  {editBlogData.map((deeee, index) => (
                    <div key={index} className="chkboxscards mb-3">
                      <div className="chkboxscardsHead">
                        <input
                          type="checkbox"
                          onClick={() =>
                            handleMasterCheckboxChange(deeee.master_name)
                          }
                          name="user_right_ddd"
                          value={`${deeee.rights_id}~@~${deeee.function_name}~@~${deeee.function_url}`}
                          id={deeee.rights_id}
                          className={`margin_right_13 trio_mandatory hidden   master_${deeee.master_name}`}
                          checked={checkboxValues[deeee.rights_id]}
                        />
                        <input
                          type="checkbox"
                          onClick={() =>
                            handleCheckboxChange(
                              deeee.rights_id,
                              deeee.master_name
                            )
                          }
                          name="user_right"
                          value={`${deeee.rights_id}~@~${deeee.function_name}~@~${deeee.function_url}`}
                          id={deeee.rights_id}
                          className={`margin_right_13  trio_mandatory  ${deeee.master_name}`}
                          checked={checkboxValues[deeee.rights_id]}
                        />
                        <label>{deeee.master_name}</label>
                      </div>

                      <div className="chkboxscardsADDAction">
                        <div className="chkboxscardsHead2">
                          <input
                            type="checkbox"
                            name={`user_action_${deeee.rights_id}`}
                            className={`margin_right_13 trio_mandatory ${deeee.rights_id} ${deeee.master_name_replace}`}
                            value="0"
                            checked={checkboxValues[deeee.rights_id]}
                          />
                          <label>Add</label>
                        </div>

                        <div className="chkboxscardsHead2">
                          <input
                            type="checkbox"
                            name={`user_other_action_${deeee.rights_id}`}
                            className={`margin_right_13 trio_mandatory margin_left_13 ${deeee.rights_id} ${deeee.master_name}`}
                            value="0"
                            checked={checkboxValues[deeee.rights_id]}
                          />
                          <label>Action</label>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* </div> */}

                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "CarrerformData",
                        save_assign_rights
                      )
                    }
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default UserMasterStaffDashboardEdit;
