import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLogin from "./Components/AdminPanel/AdminLogin";
import AdminDashboard from "./Components/AdminPanel/AdminDashboard";
import BlogDashboardView from "./Components/AdminPanel/BlogDashboardView";
import BlogDashboardAdd from "./Components/AdminPanel/BlogDashboardAdd";
import SeoDashboardView from "./Components/AdminPanel/SeoDashboardView";
import SeoDashboardAdd from "./Components/AdminPanel/SeoDashboardAdd";
import ImageDashboardView from "./Components/AdminPanel/ImageDashboardView";
import ImageDashboardAdd from "./Components/AdminPanel/ImageDashboardAdd";
import TestimonialDashboardView from "./Components/AdminPanel/TestimonialDashboardView";
import WebsiteDashboardAdd from "./Components/AdminPanel/WebsiteDashboardAdd";
import TestimonialDashboardAdd from "./Components/AdminPanel/TestimonialDashboardAdd";
import SubscribeDashboardView from "./Components/AdminPanel/SubscribeDashboardView";
import SliderDashboardView from "./Components/AdminPanel/SliderDashboardView";
import SliderDashboardAdd from "./Components/AdminPanel/SliderDashboardAdd";
import StaffDashboardView from "./Components/AdminPanel/StaffDashboardView";
import StaffDashboardAdd from "./Components/AdminPanel/StaffDashboardAdd";
import EnquiryDashboardAdd from "./Components/AdminPanel/EnquiryDashboardAdd";
import EnquiryDashboardView from "./Components/AdminPanel/EnquiryDashboardView";
import EnquiryDashboardDetails from "./Components/AdminPanel/EnquiryDashboardDetails";
import EnquiryDashboardAskAgain from "./Components/AdminPanel/EnquiryDashboardAskAgain";
import EnquiryDashboardConfirm from "./Components/AdminPanel/EnquiryDashboardConfirm";
import CustomerBookingDashboardView from "./Components/AdminPanel/CustomerBookingDashboardView";
import CustomerBookingDashboardDetails from "./Components/AdminPanel/CustomerBookingDashboardDetails";
import CustomerBookingInvoice from "./Components/AdminPanel/CustomerBookingInvoice";
import EnquiryAssignDashboardView from "./Components/AdminPanel/EnquiryAssignDashboardView";
import EnquiryCompleteDashboardView from "./Components/AdminPanel/EnquiryCompleteDashboardView";
import LandmarkDashboardView from "./Components/AdminPanel/LandmarkDashboardView";
import LandmarkDashboardAdd from "./Components/AdminPanel/LandmarkDashboardAdd";
import UserMasterDashboardView from "./Components/AdminPanel/UserMasterDashboardView";
import UserMasterDashboardAdd from "./Components/AdminPanel/UserMasterDashboardAdd";
import UserMasterStaffDashboardEdit from "./Components/AdminPanel/UserMasterStaffDashboardEdit";
import LeadDashboardView from "./Components/AdminPanel/LeadDashboardView";
import "font-awesome/css/font-awesome.min.css";
import "./Css/AdminPanel.css";
import "./Css/Loading.css";
import "./Css/AdminLogin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "aos/dist/aos.css";

import EnquiryDashboardViewDetails from "./Components/AdminPanel/EnquiryDashboardViewDetails.js";
import AdminCityAdd from "./Components/AdminPanel/AdminCityAdd.js";
import AdminCityView from "./Components/AdminPanel/AdminCityView.js";
import VenueDeshboardView from "./Components/AdminPanel/VenueDeshboardView.js";
import VenueDeshboardAdd from "./Components/AdminPanel/VenueDeshboardAdd.js";
import CategoryDeshboardView from "./Components/AdminPanel/CategoryDeshboardView.js";
import CategoryDeshboardAdd from "./Components/AdminPanel/CategoryDeshboardAdd.js";
import PackageVenueView from "./Components/AdminPanel/PackageVenueView.js";
import ExtraAddonVenueView from "./Components/AdminPanel/ExtraAddonVenueView.js";
import PackageVenueAdd from "./Components/AdminPanel/PackageVenueAdd.js";
import ExtraAddonVenueAdd from "./Components/AdminPanel/ExtraAddonVenueAdd.js";
import AmenitiesDeshboardAdd from "./Components/AdminPanel/AmenitiesDeshboardAdd.js";
import AmenitiesDeshboardView from "./Components/AdminPanel/AmenitiesDeshboardView.js";
import AmenitiesVenueAdd from "./Components/AdminPanel/AmenitiesVenueAdd.js";
import AmenitiesVenueView from "./Components/AdminPanel/AmenitiesVenueView.js";
import DayWiseRateView from "./Components/AdminPanel/DayWiseRateView.js";
import DayWiseRateAdd from "./Components/AdminPanel/DayWiseRateAdd.js";
import CategoryVenueView from "./Components/AdminPanel/CategoryVenueView.js";
import CategoryVenueAdd from "./Components/AdminPanel/CategoryVenueAdd.js";
import ImageVenueAdd from "./Components/AdminPanel/ImageVenueAdd.js";
import ImageVenueView from "./Components/AdminPanel/ImageVenueView.js";
import FaqDeshboardView from "./Components/AdminPanel/FaqDeshboardView.js";
import FaqDeshboardAdd from "./Components/AdminPanel/FaqDeshboardAdd.js";
import VenueOwnerView from "./Components/AdminPanel/VenueOwnerView.js";
import CustomerDeshboardView from "./Components/AdminPanel/CustomerDeshboardView.js";
import Reports from "./Components/AdminPanel/Reports.js";
import BookingStatusView from "./Components/AdminPanel/BookingStatusView.js";
import BookingStatusDetails from "./Components/AdminPanel/BookingStatusDetails.js";
import CancleEnquiryView from "./Components/AdminPanel/CancleEnquiryView.js";
import CancelEnquiryDetails from "./Components/AdminPanel/CancelEnquiryDetails.js";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/adminlogin*" element={<AdminLogin />} /> */}
        <Route path="/" element={<AdminLogin />} />
        <Route path="/admindashboard" element={<AdminDashboard />} />
        <Route path="/FaqDeshboardView" element={<FaqDeshboardView />} />
        <Route path="/FaqDeshboardAdd" element={<FaqDeshboardAdd />} />
        <Route path="/FaqDeshboardEdit/:id" element={<FaqDeshboardAdd />} />
        <Route path="/blogDashboardView" element={<BlogDashboardView />} />
        <Route path="/BlogDashboardAdd" element={<BlogDashboardAdd />} />
        <Route path="/BlogDashboardEdit/:id" element={<BlogDashboardAdd />} />
        <Route path="/seoDashboardView" element={<SeoDashboardView />} />
        <Route path="/SeoDashboardAdd" element={<SeoDashboardAdd />} />
        <Route path="/SeoDashboardEdit/:id" element={<SeoDashboardAdd />} />
        <Route path="/imageDashboardView" element={<ImageDashboardView />} />
        <Route path="/ImageDashboardAdd" element={<ImageDashboardAdd />} />
        <Route path="/ImageDashboardEdit/:id" element={<ImageDashboardAdd />} />
        <Route
          path="/testimonialDashboardView"
          element={<TestimonialDashboardView />}
        />
        <Route path="/VenueOwnerView" element={<VenueOwnerView />} />
        <Route path="/BookingStatusView" element={<BookingStatusView />} />
        <Route path="/CancelEnquiryView" element={<CancleEnquiryView />} />
        <Route
          path="/CancelEnquiryDetails/:id"
          element={<CancelEnquiryDetails />}
        />

        <Route
          path="/BookingStatusDetails/:id"
          element={<BookingStatusDetails />}
        />
        <Route
          path="/CustomerDeshboardView"
          element={<CustomerDeshboardView />}
        />
        <Route path="/AdminCityView" element={<AdminCityView />} />
        <Route path="/VenueDeshboardView" element={<VenueDeshboardView />} />
        <Route path="/VenueDeshboardAdd" element={<VenueDeshboardAdd />} />
        <Route path="/VenueDeshboardEdit/:id" element={<VenueDeshboardAdd />} />
        <Route
          path="/VenueDeshboardEdit/:id/PackageVenueView"
          element={<PackageVenueView />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/PackageVenueAdd"
          element={<PackageVenueAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/PackageVenueEdit/:eid"
          element={<PackageVenueAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/ExtraAddonVenueView"
          element={<ExtraAddonVenueView />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/ExtraAddonVenueAdd"
          element={<ExtraAddonVenueAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/AmenitiesVenueAdd"
          element={<AmenitiesVenueAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/AmenitiesVenueView"
          element={<AmenitiesVenueView />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/AmenitiesVenueEdit/:eid"
          element={<AmenitiesVenueAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/ExtraAddonVenueEdit/:eid"
          element={<ExtraAddonVenueAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/DayWiseRateView"
          element={<DayWiseRateView />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/DayWiseRateAdd"
          element={<DayWiseRateAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/DayWiseRateEdit/:eid"
          element={<DayWiseRateAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/CategoryVenueView"
          element={<CategoryVenueView />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/CategoryVenueAdd"
          element={<CategoryVenueAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/CategoryVenueEdit/:eid"
          element={<CategoryVenueAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/ImageVenueAdd"
          element={<ImageVenueAdd />}
        />
        <Route
          path="/VenueDeshboardEdit/:id/ImageVenueView"
          element={<ImageVenueView />}
        />
        <Route
          path="/AmenitiesDeshboardAdd"
          element={<AmenitiesDeshboardAdd />}
        />
        <Route
          path="/AmenitiesDeshboardEdit/:id"
          element={<AmenitiesDeshboardAdd />}
        />
        <Route
          path="/AmenitiesDeshboardView"
          element={<AmenitiesDeshboardView />}
        />
        <Route
          path="/CategoryDeshboardView"
          element={<CategoryDeshboardView />}
        />
        <Route
          path="/CategoryDeshboardAdd"
          element={<CategoryDeshboardAdd />}
        />
        <Route
          path="/CategoryDeshboardEdit/:id"
          element={<CategoryDeshboardAdd />}
        />
        <Route
          path="/testimonialDashboardAdd"
          element={<TestimonialDashboardAdd />}
        />
        <Route
          path="/testimonialDashboardEdit/:id"
          element={<TestimonialDashboardAdd />}
        />
        <Route path="/AdminCityAdd" element={<AdminCityAdd />} />
        <Route path="/AdminCityEdit/:id" element={<AdminCityAdd />} />
        <Route path="/websiteDashboardAdd" element={<WebsiteDashboardAdd />} />
        <Route
          path="/subscribeDashboardView"
          element={<SubscribeDashboardView />}
        />
        <Route path="/sliderDashboardView" element={<SliderDashboardView />} />
        <Route path="/sliderDashboardAdd" element={<SliderDashboardAdd />} />
        <Route
          path="/sliderDashboardEdit/:id"
          element={<SliderDashboardAdd />}
        />

        <Route path="/staffDashboardView" element={<StaffDashboardView />} />
        <Route path="/staffDashboardAdd" element={<StaffDashboardAdd />} />
        <Route path="/staffDashboardEdit/:id" element={<StaffDashboardAdd />} />

        {/* ispe kaam nhi krna h */}
        <Route path="/enquiryDashboardAdd" element={<EnquiryDashboardAdd />} />
        <Route
          path="/enquiryDashboardView"
          element={<EnquiryDashboardView />}
        />
        <Route
          path="/enquiryDashboardViewDetails/:id"
          element={<EnquiryDashboardViewDetails />}
        />
        <Route
          path="/enquiryAssignDashboardView"
          element={<EnquiryAssignDashboardView />}
        />
        <Route
          path="/enquiryCompleteDashboardView"
          element={<EnquiryCompleteDashboardView />}
        />
        <Route
          path="/CustomerBookingDashboardView"
          element={<CustomerBookingDashboardView />}
        />
        <Route
          path="/enquiryDashboardDetails/:id"
          element={<EnquiryDashboardDetails />}
        />
        <Route
          path="/enquiryDashboardAgain/:id"
          element={<EnquiryDashboardAskAgain />}
        />
        <Route
          path="/enquiryCompleteDashboardDetails/:id"
          element={<EnquiryDashboardAskAgain />}
        />
        <Route
          path="/EnquiryDashboardConfirm/:id"
          element={<EnquiryDashboardConfirm />}
        />
        <Route
          path="/CustomerBookingDashboardDetails/:id"
          element={<CustomerBookingDashboardDetails />}
        />
        <Route
          path="/CustomerBookingInvoice/:id"
          element={<CustomerBookingInvoice />}
        />

        <Route
          path="/landmarkDashboardView"
          element={<LandmarkDashboardView />}
        />
        <Route path="/report" element={<Reports />} />
        <Route
          path="/landmarkDashboardAdd"
          element={<LandmarkDashboardAdd />}
        />
        <Route
          path="/landmarkDashboardEdit/:id"
          element={<LandmarkDashboardAdd />}
        />
        <Route
          path="/UsermasterDashboardView"
          element={<UserMasterDashboardView />}
        />
        <Route path="/leaddashboardview" element={<LeadDashboardView />} />
        <Route
          path="/UsermasterDashboardAdd"
          element={<UserMasterDashboardAdd />}
        />
        <Route
          path="/UsermasterDashboardEdit/:id"
          element={<UserMasterDashboardAdd />}
        />
        <Route
          path="/UserMasterStaffDashboardEdit/:id"
          element={<UserMasterStaffDashboardEdit />}
        />

        {/* admin panel */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
