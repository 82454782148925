import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import LeadDash from "../../Css/LeadDash.css";
import DeleteBlack from "../../Assests/images/deleteBlack1.svg";
import {
  server_post_data,
  get_multi_images,
  APL_LINK,
  delete_update_data,
} from "../../ServiceConnection/serviceconnection.js";
import {
  fnExcelReport,
  handleLinkClick,
  handleConfimDeleteClick,
  handleSuccess,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { Link, useParams } from "react-router-dom";
function ImageVenueView() {
  let { id } = useParams();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [BlogData, setsBlogData] = useState([]);
  const retrievedAdminId = retrieveData("staff_id");

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const [SingleBlogData, setsSingleBlogData] = useState([]);
  // Initialize end date as today
  const today = new Date();

  useEffect(() => {
    const call_id = id;
    master_data_get(call_id);
  }, []);

  const master_data_get = async (call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("venue_id", call_id);
    await server_post_data(get_multi_images, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setsBlogData(Response.data.message.venue_images);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action = async (call_id) => {
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("id_for_delete", call_id);
      fd.append("flag_for", "22");
      fd.append("for_status_final", "d");
      console.log(call_id);
      await server_post_data(delete_update_data, fd)
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log(Response.data);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccess(Response.data.message);
            master_data_get(id);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Venue Image Management
                    <div className="page-title-subheading">
                      Where You Can Check Venue Image
                    </div>
                  </div>
                </div>
                <div className="page-title-actions add_funtion_call">
                  <Link
                    onClick={() =>
                      handleLinkClick(`/VenueDeshboardEdit/${id}/ImageVenueAdd`)
                    }
                  >
                    <button
                      type="button"
                      className="btn-shadow mr-3 btn btn-dark"
                    >
                      <i className="fa fa-plus">&nbsp;Add Images</i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12">
                <div className="main-card mb-3 card">
                  <div className="card-header card-header-view">
                    <div className="btn-actions-pane-left row">
                      <div className="col-12 ">
                        <div className="row">
                          {BlogData.map((option, index) => (
                            <div className="col-md-3" key={index}>
                              <img
                                className="uploadedImgLink"
                                src={APL_LINK + "/assets/" + option.image_name}
                                alt="bookmyvenue Admin"
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  margin: "0.5rem",
                                }}
                              />

                              <button
                                onClick={() =>
                                  master_data_action(option.primary_id)
                                }
                                style={{
                                  background: "transparent",
                                  border: "1px solid grey",
                                  height: "fitContent !important",
                                  borderRadius: "8px",
                                  padding: "0.3rem 0.8rem",
                                }}
                              >
                                <img
                                  src={DeleteBlack}
                                  alt="bookmyvenue Admin"
                                  className="pe-none"
                                />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="OvrFlow"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <AdminFooter />
      </div>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}
export default ImageVenueView;
