import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  save_update_venue,
  get_all_venue,
  get_all_venue_data,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handlePasswordChange,
  handleSuccessSession,
  handleError,
  computeTodayDate,
  computeFutureDate,
  handleAphabetswithhashChange,
  handleAlphabetsWithoutSpaceChange,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
function VenueDeshboardAdd() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editBlogData, seteditBlogData] = useState([]);
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = retrieveData("staff_id");
  const admin_vendor = retrieveData("admin_profession");
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [venueData, setVenueData] = useState([]);
  const [venueidData, setVenueidData] = useState("0");
  const [checkStaffData, setcheckStaffData] = useState("0");
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  console.log(venueidData);
  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    if (parts.length !== 1) {
      const start_date = "";
      const end_date = "";
      const flag = "3";
      let call_id = parts[1];
      master_data_get(start_date, end_date, flag, call_id);
    }

    try {
      const input = document.getElementById("searchInput");
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ["(cities)"], // Restrict results to cities
      });

      autocomplete.addListener("place_changed", function () {
        const place = autocomplete.getPlace();
        let full_address = place.address_components;
        let length_data = place.address_components.length;
        let citys = "";
        let state = "";
        let country = "";
        let tehsil = "";

        for (let i = 0; i < length_data; i++) {
          if (full_address[i].types[0] === "administrative_area_level_1") {
            state = full_address[i].long_name;
          } else if (full_address[i].types[0] === "country") {
            country = full_address[i].long_name;
          } else if (
            full_address[i].types[0] === "administrative_area_level_2"
          ) {
            citys = full_address[i].long_name;
          } else if (full_address[i].types[0] === "locality") {
            tehsil = full_address[i].long_name;
          }
        }
        if (tehsil !== "") {
          citys = tehsil;
        }
        document.getElementById("admin_city").value = citys;
        document.getElementById("admin_state").value = state;
        document.getElementById("admin_country").value = country;
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_venue, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data.message);
          seteditBlogData(Response.data.message[0]);
          setEditorDatMainID(Response.data.message[0].primary_id);
          seteditOldImageData(Response.data.message[0].venue_images);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get1();
  }, []);
  const master_data_get1 = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    await server_post_data(get_all_venue_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setVenueData(Response.data.message.venue_owners);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("admin_id", retrievedAdminId);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("admin_image", editOldImageData);
      fd_from.append("admin_vendor", admin_vendor);
      fd_from.append("admin_id", venueidData);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/VenueDeshboardView");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Venue Management
                    <div className="page-title-subheading">
                      Add and Edit Venue
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Add/Edit Venue</h5>
                <form className="needs-validation" id="CarrerformData">
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Name<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_name  trio_mandatory"
                        name="venue_name"
                        id="venue_name"
                        maxLength={200}
                        required
                        // onInput={handleAphabetsChange}
                        placeholder="Enter Name"
                        defaultValue={editBlogData.venue_name || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Full Address<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control   trio_mandatory"
                        name="type_address"
                        id="type_address"
                        maxLength={500}
                        required
                        // onInput={handleIaphabetnumberChange}
                        placeholder="Enter Full Address"
                        defaultValue={editBlogData.type_address || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Landmark<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_name  trio_mandatory"
                        name="landmark"
                        id="landmark"
                        maxLength={100}
                        required
                        // onInput={handleAphabetsChange}
                        placeholder="Enter landmark"
                        defaultValue={editBlogData.landmark || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row hidden">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01"> City</label>
                      <input
                        type="text"
                        className="form-control  searchInput_google"
                        name="admin_city"
                        id="admin_city"
                        maxLength={200}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City"
                        defaultValue={editBlogData.city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01"> State</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="admin_state"
                        id="admin_state"
                        maxLength={45}
                        onInput={handleAphabetsChange}
                        placeholder="Enter State"
                        defaultValue={editBlogData.state || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">Country</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="admin_country"
                        id="admin_country"
                        maxLength={45}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Country"
                        defaultValue={editBlogData.country || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Search City<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_mandatory "
                        name="searchInput"
                        id="searchInput"
                        maxLength={200}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City Name"
                        defaultValue={editBlogData.city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Zipcode<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory trio_zip"
                        name="admin_zipcode"
                        id="admin_zipcode"
                        maxLength={6}
                        onInput={handleNumbersChange}
                        placeholder="Enter Zipcode"
                        defaultValue={editBlogData.pincode || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Description</label>

                      <textarea
                        type="text"
                        className="form-control  "
                        name="description"
                        id="description"
                        maxLength={1500}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Special Requirement"
                        defaultValue={editBlogData.description || ""}
                      ></textarea>
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Note</label>
                      <textarea
                        type="text"
                        className="form-control  "
                        name="note"
                        id="note"
                        maxLength={1500}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Special Requirement"
                        defaultValue={editBlogData.extra_description || ""}
                      ></textarea>
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Price Per Day<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control   trio_mandatory"
                        name="price_per_day"
                        id="price_per_day"
                        maxLength={10}
                        required
                        onInput={handleNumbersChange}
                        placeholder="Enter  Price Per Day"
                        defaultValue={editBlogData.price_per_day || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Price Per Hour<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control   trio_mandatory"
                        name="price_per_hour"
                        id="price_per_hour"
                        maxLength={10}
                        required
                        onInput={handleNumbersChange}
                        placeholder="Enter Price Per Hour"
                        defaultValue={editBlogData.price_per_hour || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Discount
                        {/* <span className="red_show">*</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control  "
                        name="discount_per"
                        id="discount_per"
                        maxLength={10}
                        onInput={handleNumbersChange}
                        placeholder="Enter Discount"
                        defaultValue={editBlogData.discount_per || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Guests Capacity<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory "
                        name="guests_capacity"
                        id="guests_capacity"
                        maxLength={10}
                        onInput={handleNumbersChange}
                        placeholder="Enter Guests Capacity"
                        defaultValue={editBlogData.guests_capacity || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Admin Type<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="admin_type"
                        id="admin_type"
                        onChange={(e) => setcheckStaffData(e.target.value)}
                      >
                        {/* <option value="">Select Type</option> */}
                        {/* {retrievedAdmin === "0" ? ( */}
                        <>
                          <option
                            value="0"
                            // selected={editBlogData.admin_vendor === "0"}
                          >
                            Super Admin
                          </option>
                          <option
                            value="2"
                            // selected={editBlogData.admin_vendor === "0"}
                          >
                            Venue Admin
                          </option>
                        </>
                      </select>
                      <span className="condition_error"></span>
                    </div>
                    {checkStaffData === "2" ? (
                      <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom01">
                          Venue Owner<span className="red_show">*</span>
                        </label>
                        <select
                          className="form-control trio_mandatory"
                          name="venue_type"
                          id="venue_type"
                          // placeholder= "Select Type"
                          onChange={(e) => setVenueidData(e.target.value)}
                        >
                          <option value="0">Select Type</option>
                          {venueData &&
                            venueData.length > 0 &&
                            venueData.map((e) => (
                              <option
                                value={e.primary_id}
                                // selected={editBlogData.admin_vendor === "0"}
                              >
                                {e.admin_name}
                              </option>
                            ))}
                        </select>
                        <span className="condition_error"></span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Images<span className="red_show">*</span>
                        <span style={{ color: "red", fontWeight: "600" }}>
                          (size 1000 x 650 upto 500 kb )
                        </span>
                      </label>
                      <br></br>
                      <input
                        className="inputEditFile"
                        type="file"
                        name="admin_image"
                        onChange={handleFileChangedynamic("admin_image")}
                        accept=".jpg,.jpeg,.png"
                        style={{ marginBottom: "1rem" }}
                      />
                      <div className="previewEditBox">
                        {dynaicimage && dynaicimage.admin_image_show && (
                          <img
                            src={dynaicimage.admin_image_show}
                            alt="Preview"
                            className="imgEditMaster"
                          />
                        )}
                      </div>
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "CarrerformData",
                        save_update_venue
                      )
                    }
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default VenueDeshboardAdd;
