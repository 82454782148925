import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  report_status,
} from "../../ServiceConnection/serviceconnection.js";
import {
  fnExcelReport,
  fnExcelReport1,
  handleLinkClick,
  inputdateformateChange,
  computeTodayDate,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { Link } from "react-router-dom";
import DeletIcon from "../../Assests/images/delete.png";
function Reports() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [BlogData, setsBlogData] = useState([]);
  const [rupess_icon, setrupess_icon] = useState([]);
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedVenderId = retrieveData("vendor_id");
  const admin_profession = retrieveData("admin_profession");
  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    await server_post_data(report_status, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data.message);
          setsBlogData(Response.data.message.customer_data);
          setrupess_icon(Response.data.message.booking_data);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  console.log(BlogData);
  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Report Dashboard
                    <div className="page-title-subheading">
                      Where You Can Check Your Report
                    </div>
                  </div>
                </div>
                <div className="btn-actions-pane-right btn-actions-pane-right-float ">
                  <div role="group" className="btn-group-sm btn-group mr-3">
                    <button
                      className="active btn btn-focus"
                      onClick={fnExcelReport1}
                    >
                      Download Customer Report
                    </button>
                  </div>
                  <div role="group" className="btn-group-sm btn-group">
                    <button
                      className="active btn btn-focus"
                      onClick={fnExcelReport}
                    >
                      Download Booking Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="mb-3 card">
                <div class="card-header">
                  <ul class="nav nav-justified">
                    <li class="nav-item">
                      <a
                        data-toggle="tab"
                        href="#tab-eg7-0"
                        class="nav-link active show"
                      >
                        Customer Report
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        data-toggle="tab"
                        href="#tab-eg7-2"
                        class="nav-link show"
                      >
                        Booking Report
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="tab-content">
                    <div
                      class="tab-pane show table-responsive"
                      id="tab-eg7-2"
                      role="tabpanel"
                    >
                      <table
                        className="align-middle mb-0 table table-borderless table-striped table-hover"
                        id="dynamic-table1"
                      >
                        <thead>
                          <tr>
                            <th className="text-center">S.No</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Mobile No</th>
                            <th className="text-center">City</th>
                            <th className="text-center">Venue</th>
                            <th className="text-center">Catagory</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rupess_icon.map((blogddd, index) => (
                            <tr key={index}>
                              <td className="text-center text-muted">
                                {index + 1}
                              </td>
                              {/* <td className="text-center">
                                {formatDateString(blogddd.entry_date)}
                              </td> */}
                              <td className="text-center">
                                {blogddd.customer_person_name}
                              </td>
                              <td className="text-center">
                                {blogddd.person_mobile_no}
                              </td>

                              <td className="text-center">
                                {blogddd.person_city}
                              </td>
                              <td className="text-center">
                                {blogddd.venue_data &&
                                blogddd.venue_data.length > 0
                                  ? blogddd.venue_data[0]["venue_name"]
                                  : "No Venue Data"}
                              </td>
                              <td className="text-center">
                                {blogddd.catagory_data &&
                                blogddd.catagory_data.length > 0
                                  ? blogddd.catagory_data[0][
                                      "category_master_name"
                                    ]
                                  : "No Catagory Data"}
                                {/* {
                                  blogddd.catagory_data[0][
                                    "category_master_name"
                                  ]
                                } */}
                              </td>

                              <td className="text-center">Completed</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className="tab-pane active show table-responsive"
                      id="tab-eg7-0"
                      role="tabpanel"
                    >
                      <table
                        className="align-middle mb-0 table table-borderless table-striped table-hover"
                        id="dynamic-table2"
                      >
                        <thead>
                          <tr>
                            <th className="text-center">S.No</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Mobile No</th>
                            <th className="text-center">No of Booking</th>
                            <th className="text-center">No of Enquiry</th>
                          </tr>
                        </thead>
                        <tbody>
                          {BlogData.map((blogddd, index) => (
                            <tr key={index}>
                              <td className="text-center text-muted">
                                {index + 1}
                              </td>

                              <td className="text-center">
                                {blogddd.owner_fname +
                                  " " +
                                  blogddd.owner_lname}
                              </td>
                              <td className="text-center">
                                {blogddd.owner_email}
                              </td>
                              <td className="text-center">
                                {blogddd.owner_moblie_no}
                              </td>
                              <td className="text-center">
                                {blogddd.completed_booking}
                              </td>
                              <td className="text-center">
                                {blogddd.no_of_booking}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default Reports;
