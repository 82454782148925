import axios from "axios";
import { retrieveData } from "../LocalConnection/LocalConnection";
const appauth_key = "bookmyvenue@2029";
let APL_LINK = "http://192.168.1.15:8000";
APL_LINK = "https://backend.bookmyvenue.ai/";
let Website_URL = "https://www.bookmyvenue.ai/";
let local_server_link_react = APL_LINK + "/api/admin_link/";

/**admin panel */
const login_to_superadmin = local_server_link_react + "login_to_superadmin/";
const save_update_blog = local_server_link_react + "save_update_blog/";
const save_update_city = local_server_link_react + "save_update_city/";
const get_all_blogs = local_server_link_react + "get_all_blogs/";
const save_update_seo = local_server_link_react + "save_update_seo/";
const update_assign_staff = local_server_link_react + "update_assign_staff/";
const get_all_seo = local_server_link_react + "get_all_seo/";
const save_update_imagelink =
  local_server_link_react + "save_update_imagelink/";
const get_all_imagelink = local_server_link_react + "get_all_imagelink/";
const save_update_menu = local_server_link_react + "save_update_menu/";
const get_all_menu = local_server_link_react + "get_all_menu/";
const save_update_website_data =
  local_server_link_react + "save_update_website_data/";
const get_all_website_data = local_server_link_react + "get_all_website_data/";
const get_all_subscribe = local_server_link_react + "get_all_subscribe/";
const save_update_testimonial =
  local_server_link_react + "save_update_testimonial/";
const get_all_testimonial = local_server_link_react + "get_all_testimonial/";
const save_update_team = local_server_link_react + "save_update_team/";
const get_all_team = local_server_link_react + "get_all_team/";
const get_extra_add_on = local_server_link_react + "get_extra_add_on/";
const save_update_package = local_server_link_react + "save_update_package/";
const save_update_silder_image =
  local_server_link_react + "save_update_silder_image/";
const get_all_slider_image = local_server_link_react + "get_all_slider_image/";
const save_update_hall = local_server_link_react + "save_update_hall/";
const get_all_hall = local_server_link_react + "get_all_hall/";
const save_update_landmark = local_server_link_react + "save_update_landmark/";
const get_all_landmark = local_server_link_react + "get_all_landmark/";
const save_update_event_timing =
  local_server_link_react + "save_update_event_timing/";
const get_all_event_timing = local_server_link_react + "get_all_event_timing/";
const save_update_day_wise_rate =
  local_server_link_react + "save_update_day_wise_rate/";
const get_venue_category = local_server_link_react + "get_venue_category/";
const save_update_faq = local_server_link_react + "save_update_faq/";
const get_customer_list = local_server_link_react + "get_customer_list/";
const delete_master_data = local_server_link_react + "delete_master_data/";
const get_booking_status = local_server_link_react + "get_booking_status/";
const assign_approve_booking =
  local_server_link_react + "assign_approve_booking/";
const get_all_venue_data = local_server_link_react + "get_all_venue_data/";
const get_all_staff_venue_list =
  local_server_link_react + "get_all_staff_venue_list/";

const save_update_event_listing =
  local_server_link_react + "save_update_event_listing/";
const get_all_event_listing =
  local_server_link_react + "get_all_event_listing/";
const save_update_gallerywebsite =
  local_server_link_react + "save_update_gallerywebsite/";
const get_all_gallerywebsite =
  local_server_link_react + "get_all_gallerywebsite/";
const get_all_staff = local_server_link_react + "get_all_staff/";
const city_list = local_server_link_react + "city_list/";
const save_update_staff = local_server_link_react + "save_update_staff/";
const save_update_main_lead =
  local_server_link_react + "save_update_main_lead/";
const get_all_main_lead = local_server_link_react + "get_all_main_lead/";
const get_all_venue = local_server_link_react + "get_all_venue/";
const get_all_category_master =
  local_server_link_react + "get_all_category_master/";

const save_again_date_remark =
  local_server_link_react + "save_again_date_remark/";
const confirm_enquiry = local_server_link_react + "confirm_enquiry/";
const get_all_customer_booking =
  local_server_link_react + "get_all_customer_booking/";
const save_amount_pay_remaing =
  local_server_link_react + "save_amount_pay_remaing/";
const delete_update_data = local_server_link_react + "delete_master_data/";

const admin_dashboard_url = local_server_link_react + "admin_dashboard_url/";
const get_all_usermaster = local_server_link_react + "get_all_usermaster/";
const save_update_usermaster =
  local_server_link_react + "save_update_usermaster/";
const save_assign_rights = local_server_link_react + "save_assign_rights/";
const get_userright_data = local_server_link_react + "get_userright_data/";
const get_all_leads = local_server_link_react + "get_all_leads/";
const change_active_status = local_server_link_react + "change_active_status/";
const save_update_venue_category_master =
  local_server_link_react + "save_update_venue_category_master/";
const save_update_venue = local_server_link_react + "save_update_venue/";
const get_packages = local_server_link_react + "get_packages/";
const save_update_extra_add_on =
  local_server_link_react + "save_update_extra_add_on/";
const get_all_master_amenities =
  local_server_link_react + "get_all_master_amenities/";
const save_update_master_amenities =
  local_server_link_react + "save_update_master_amenities/";
const get_all_amenities = local_server_link_react + "get_all_amenities/";
const save_update_amenities =
  local_server_link_react + "save_update_amenities/";
const get_all_day_wise_rate =
  local_server_link_react + "get_all_day_wise_rate/";
const save_venue_category = local_server_link_react + "save_venue_category/";
const add_multi_images = local_server_link_react + "add_multi_images/";
const get_multi_images = local_server_link_react + "get_multi_images/";
const get_all_admin_staff = local_server_link_react + "get_all_admin_staff/";
const save_update_admin_website =
  local_server_link_react + "save_update_admin_website/";
const get_all_website_list = local_server_link_react + "get_all_website_list/";
const get_all_faq = local_server_link_react + "get_all_faq/";
const get_all_venueowner = local_server_link_react + "get_all_venueowner/";
const report_status = local_server_link_react + "report_status/";
const cancle_enquiry = local_server_link_react + "cancle_enquiry/";
const get_all_cancle_enqurry =
  local_server_link_react + "get_all_cancle_enqurry/";

// Retrieving data
const venue_id = retrieveData("venue_id");
console.log(venue_id);
const server_post_data = async (url_for, form_data) => {
  // const headers = {
  //   "Content-Type": "application/json",
  // };

  if (form_data === null) {
    form_data = new FormData();
  }

  form_data.append("appauth_key", appauth_key);
  form_data.append("venue_id", venue_id);

  return axios.post(url_for, form_data);
};

export {
  APL_LINK,
  Website_URL,
  appauth_key,
  server_post_data,

  /*Admin api */
  login_to_superadmin,
  save_update_blog,
  get_all_blogs,
  delete_update_data,
  save_update_seo,
  get_all_seo,
  save_update_imagelink,
  get_all_imagelink,
  save_update_menu,
  get_all_menu,
  save_update_website_data,
  get_all_website_data,
  save_update_testimonial,
  get_all_testimonial,
  save_update_team,
  get_all_team,
  get_all_subscribe,
  save_update_silder_image,
  get_all_slider_image,
  save_update_hall,
  get_all_hall,
  save_update_event_timing,
  get_all_event_timing,
  save_update_event_listing,
  get_all_event_listing,
  save_update_gallerywebsite,
  get_all_gallerywebsite,
  save_update_staff,
  get_all_staff,
  get_all_main_lead,
  save_update_main_lead,
  save_again_date_remark,
  confirm_enquiry,
  get_all_customer_booking,
  save_amount_pay_remaing,
  save_update_landmark,
  get_all_landmark,
  admin_dashboard_url,
  get_all_usermaster,
  save_update_usermaster,
  save_assign_rights,
  get_userright_data,
  update_assign_staff,
  get_all_leads,
  city_list,
  save_update_city,
  get_all_venue,
  get_all_category_master,
  save_update_venue_category_master,
  change_active_status,
  save_update_venue,
  get_packages,
  get_extra_add_on,
  save_update_package,
  save_update_extra_add_on,
  get_all_master_amenities,
  save_update_master_amenities,
  get_all_amenities,
  save_update_amenities,
  save_update_day_wise_rate,
  get_all_day_wise_rate,
  get_venue_category,
  save_venue_category,
  add_multi_images,
  get_multi_images,
  get_all_admin_staff,
  save_update_admin_website,
  get_all_website_list,
  save_update_faq,
  get_all_faq,
  get_all_venueowner,
  get_customer_list,
  delete_master_data,
  report_status,
  get_booking_status,
  cancle_enquiry,
  get_all_cancle_enqurry,
  assign_approve_booking,
  get_all_venue_data,
  get_all_staff_venue_list,
  // update_assign_staff,
  /*Admin api */
  /**Website  panel */
  // save_subscribe,
  // get_all_booking_data,
  // save_enquiry_website,
  // get_all_home_front,
  // seo_data_url,
  // get_all_home_gallery,
  // get_all_home_about,
  // get_blog_details_url,
  /**Website  panel */
};
