import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  confirm_enquiry,
  get_all_main_lead,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  computeTodayDate,
  handleDateChange,
  computeFutureDate,
  handleNumbersDecimalChange,
  handleSuccessSession,
  handleError,
  formatDateString,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
function EnquiryDashboardConfirm() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editBlogData, seteditBlogData] = useState([]);
  const [Category, setCategory] = useState([]);
  const [HalllistData, seteditHalllistData] = useState([]);
  const [MenulistData, setMenulistData] = useState([]);
  const [MenulistSelectedData, setMenulistSelectedData] = useState([]);
  const [ProcesslistData, setProcesslistData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [EventTiminglistData, setEventTiminglistData] = useState([]);
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [error, setError] = useState("");
  const [RUPESSICON, setRUPESSICON] = useState("₹");
  const [totalAmount, setTotalAmount] = useState("");
  const [totalAmountData, setTotalAmountData] = useState("");
  const [AddOnAmount, setAddOnAmount] = useState(0);
  const [extraAddOn, setExtraAddon] = useState(["0"]);
  const [gstfinalAmount, setgstAmount] = useState("");
  const [finaltotalAmount, setfinaltotalAmount] = useState("");
  const [selectedValue, setSelectedValue] = useState("0"); // Initial valuex`
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedvendor_id = retrieveData("vendor_id");
  const retrievedvendor_type = retrieveData("admin_profession");
  const retrievedAdminName = retrieveData("admin_name");
  const [seteventlistname, setseteventlistname] = useState([]);
  const [packages, setPackages] = useState([]);
  const [extraAddonData, setExtraAddOn] = useState([]);
  const [packageId, setPackageId] = useState(0);
  const [dueAmount, setDueAmount] = useState("");

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "5";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    try {
      const input = document.getElementById("searchInput");
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ["(cities)"], // Restrict results to cities
      });

      autocomplete.addListener("place_changed", function () {
        const place = autocomplete.getPlace();
        let full_address = place.address_components;
        let length_data = place.address_components.length;
        let citys = "";
        let state = "";
        let country = "";
        let tehsil = "";

        for (let i = 0; i < length_data; i++) {
          if (full_address[i].types[0] === "administrative_area_level_1") {
            state = full_address[i].long_name;
          } else if (full_address[i].types[0] === "country") {
            country = full_address[i].long_name;
          } else if (
            full_address[i].types[0] === "administrative_area_level_2"
          ) {
            citys = full_address[i].long_name;
          } else if (full_address[i].types[0] === "locality") {
            tehsil = full_address[i].long_name;
          }
        }
        if (tehsil !== "") {
          citys = tehsil;
        }
        document.getElementById("lead_person_city").value = citys;
        document.getElementById("lead_person_state").value = state;
        document.getElementById("lead_person_country").value = country;
      });
    } catch (error) {
      console.log(error);
    }

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("select_resource", "All");
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_main_lead, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_venue.length > 0) {
            console.log(Response.data.message.data_venue[0]);
            seteditBlogData(Response.data.message.data_venue[0]);
            setCategory(
              Response.data.message.data_venue[0].data_CategoryMaster[0]
                .category_master_name
            );
            setEditorDatMainID(Response.data.message.data_venue[0].primary_id);
            setPackages(
              Response.data.message.data_venue[0].data_CustomerDataLead[0]
                .total_amount
            );
            setAdvanceAmount(Response.data.message.data_venue[0].package_data);
            setExtraAddOn(Response.data.message.data_venue[0].addon_data);
            setfinaltotalAmount(
              Response.data.message.data_venue[0].data_CustomerDataLead[0]
                .final_amount
            );
            seteditHalllistData(
              Response.data.message.data_venue[0].data_venue_final[0].venue_name
            );
          }

          // setMenulistData(Response.data.message.data_menulist);
          // setMenulistSelectedData(Response.data.message.data_menuselectlist);
          // setEventTiminglistData(Response.data.message.data_eventtiming);
          setRUPESSICON(Response.data.message.rupess_icon);
          // setseteventlistname(Response.data.message.data_evenlist);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("staff_id", retrievedAdminId);
      fd_from.append("staff_name", retrievedAdminName);
      fd_from.append("gst_amount", gstfinalAmount);
      fd_from.append("add_on_amount", AddOnAmount);
      fd_from.append("from", editBlogData.event_timing_from);
      fd_from.append("to", editBlogData.event_timing_to);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("vendor_id", retrievedvendor_id);
      fd_from.append("admin_vender", retrievedvendor_type);
      fd_from.append("extraAddOn", extraAddonData);
      fd_from.append("packageId", packageId);
      fd_from.append("lead_for_venue_id", editBlogData.lead_for_venue_id);
      fd_from.append("lead_for_category_id", editBlogData.lead_for_category_id);
      fd_from.append("customer_id", editBlogData.customer_id);

      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(
              Response.data.message,
              "/CustomerBookingDashboardView"
            );
            if (editorDataMainID === "0") {
              empty_form(form_data);
            }
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleMenuChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const selectedMenuDetails = selectedOptions.map((option) =>
      option.value.split("~@~")
    );
    // Calculate total amount based on selected menu prices
    const newTotalAmount = selectedMenuDetails.reduce(
      (total, [, , menuPrice, menuDiscount]) => {
        let price = parseFloat(menuPrice);
        if (menuDiscount > 0) {
          price = parseFloat(menuDiscount);
        }
        return total + price;
      },
      0
    );

    let newValue = document.getElementById("gst_per").value;
    let add_on_amount = document.getElementById("add_on_amount").value;
    let lead_advance_amount = document.getElementById(
      "lead_advance_amount"
    ).value;
    let lead_event_no_of_person_come = Number(
      document.getElementById("lead_event_no_of_person_come").value
    );
    let amount_show = lead_event_no_of_person_come * Number(newTotalAmount);

    calculategstfinalamount(amount_show, add_on_amount, newValue);
    setTotalAmount(amount_show);
    document.getElementById("lead_total_amount").value = amount_show;
    if (Number(lead_advance_amount) > Number(amount_show)) {
      document.getElementById("lead_advance_amount").value = 0;
    }
  };

  const NoOfGuestChange = (event) => {
    let amount_show = 0;

    calculategstfinalamount(amount_show, 0, 0);
    setTotalAmount(amount_show);
    document.getElementById("lead_total_amount").value = amount_show;
    document.getElementById("lead_advance_amount").value = 0;
  };
  const handleBlur = () => {
    const totalAmountValue = parseFloat(totalAmount);
    const advanceAmountValue = parseFloat(advanceAmount);
    console.log(totalAmountValue > advanceAmountValue);
    if (
      !isNaN(totalAmountValue) &&
      !isNaN(advanceAmountValue) &&
      totalAmountValue > advanceAmountValue
    ) {
      setAdvanceAmount("0");
    }
  };

  const handleTotalAmountChange = (e) => {
    const enteredTotalAmount = e.target.value;
    setTotalAmount(enteredTotalAmount);

    // Check if Advance Amount is greater than Total Amount
    if (
      advanceAmount !== "" &&
      parseFloat(advanceAmount) > parseFloat(enteredTotalAmount)
    ) {
      setError("Advance Amount cannot be greater than Final Amount.");
      setAdvanceAmount("0");
    } else {
      setError("");
    }
  };

  const handleAdvanceAmountChange = (event) => {
    const enteredAdvanceAmount = parseFloat(event.target.value);
    const totalAmount = parseFloat(
      document.getElementById("final_amount").value
    );
    const dueamount = totalAmount - enteredAdvanceAmount;
    if (enteredAdvanceAmount > totalAmount) {
      event.target.value = "";
      setError("Advance amount cannot be greater than total amount");
    } else {
      setDueAmount(dueamount);
      setAdvanceAmount(enteredAdvanceAmount);
      setError("");
    }
  };

  const calculategstfinalamount = (
    lead_total_amount,
    add_on_amount,
    gst_per
  ) => {
    // Calculate GST amount

    let base_amount = Number(lead_total_amount) + Number(add_on_amount);
    const gstAmount = (Number(base_amount) * gst_per) / 100;
    // Calculate total amount including GST
    setgstAmount(gstAmount);
    let final_total_amount = Number(base_amount) + Number(gstAmount);

    document.getElementById("final_amount_set").value = final_total_amount;
    document.getElementById("final_amount").value = final_total_amount;
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    let lead_total_amount = document.getElementById("lead_total_amount").value;
    let add_on_amount = document.getElementById("add_on_amount").value;
    calculategstfinalamount(lead_total_amount, add_on_amount, newValue);
  };

  const handleAddonChange = (event) => {
    const add_on_amount = event.target.value;
    setAddOnAmount(add_on_amount);
    let lead_total_amount = document.getElementById("lead_total_amount").value;
    let newValue = document.getElementById("gst_per").value;
    calculategstfinalamount(lead_total_amount, add_on_amount, newValue);
  };

  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const handleAmountData = (data) => {
    let arr = data.split("~@~");
    setTotalAmount(arr[0]);
    setPackageId(arr[1]);

    let newValue = document.getElementById("gst_per").value;
    let lead_total_amount = arr[0];
    let add_on_amount = document.getElementById("add_on_amount").value;
    calculategstfinalamount(lead_total_amount, add_on_amount, newValue);
  };

  const handleextraaddon = (event) => {
    let newValue = document.getElementById("gst_per").value;
    let lead_total_amount = document.getElementById("lead_total_amount").value;
    const value = event.target.value;
    let updatedAddOns = [...selectedAddOns];

    if (event.target.checked) {
      updatedAddOns.push(value);
    } else {
      updatedAddOns = updatedAddOns.filter((addOn) => addOn !== value);
    }
    setSelectedAddOns(updatedAddOns);
    const selectedMenuDetails = updatedAddOns.map((addOn) => addOn.split("@"));
    let finalAmount = 0;
    let addonid = [];
    console.log(selectedMenuDetails);
    selectedMenuDetails.forEach((e) => {
      finalAmount += parseFloat(e[2]);
      addonid.push(e[0]);
    });
    setAddOnAmount(finalAmount);
    setExtraAddOn(addonid);
    calculategstfinalamount(lead_total_amount, finalAmount, newValue);
  };
  // console.log(editBlogData.staff_data[0]["admin_name"]);
  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Enquiry Dashboard
                    <div className="page-title-subheading">Confirm Enquiry</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <span className="d-flex flex-row">
                  <h5 className="card-title">Enquiry Process</h5>
                  <h5 className="card-title ml-auto mr-2">
                    Assigned by: {"( "}{" "}
                    {editBlogData &&
                      editBlogData.staff_data &&
                      editBlogData.staff_data.length > 0 &&
                      editBlogData.staff_data[0].admin_name}{" "}
                    {")"}
                  </h5>
                </span>
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Enquiry Start Date :-
                        <span className="float_right_show">
                          {formatDateString(editBlogData.lead_start_date) || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Venue Name :-
                        <span className="float_right_show">
                          {/* {editBlogData.data_venue_final[0].venue_name} */}
                          {editBlogData &&
                          Array.isArray(editBlogData.data_venue_final) &&
                          editBlogData.data_venue_final.length > 0
                            ? editBlogData.data_venue_final[0].venue_name
                            : "Venue name not available"}
                        </span>
                      </button>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Booking Type :-
                        <span className="float_right_show">
                          {editBlogData.lead_type || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Enquiry Type :-
                        <span className="float_right_show">
                          {editBlogData.lead_status || ""}
                        </span>
                      </button>
                    </ul>
                  </div>

                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Event Date :-
                        <span className="float_right_show">
                          {formatDateString(editBlogData.lead_event_date)}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        No of Guest :-
                        <span className="float_right_show">
                          {editBlogData.lead_event_no_of_person_come || ""}
                        </span>
                      </button>
                      {editBlogData.special_requirement &&
                        editBlogData.special_requirement.length > 2 && (
                          <button className=" list-group-item-action list-group-item">
                            Special Requirement :-
                            <span className="float_right_show">
                              {editBlogData.special_requirement || ""}
                            </span>
                          </button>
                        )}
                    </ul>
                  </div>
                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Event Timing :-
                        <span className="float_right_show">
                          {/* {EventTiminglistData.map((data_get) => {
                            if (
                              Number(data_get.primary_id) ===
                              Number(editBlogData.lead_for_eventtiming_id)
                            ) {
                              return (
                                <span
                                  className="float_right_show"
                                  key={data_get.primary_id}
                                >
                                  {editBlogData.event_list_name}{" "}
                                  {data_get.timing_name} ({data_get.timing_from}{" "}
                                  - {data_get.timing_to})
                                </span>
                              );
                            }
                            return null; // You may want to handle the case when there is no match
                          })} */}
                          From: {editBlogData.event_timing_from} To:{" "}
                          {editBlogData.event_timing_to}
                        </span>
                      </button>
                      {/* <button className=" list-group-item-action list-group-item">
                        Total Amount :-
                        <span className="float_right_show">
                          {RUPESSICON} {editBlogData.lead_total_amount || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Advance Amount :-
                        <span className="float_right_show">
                          {RUPESSICON} {editBlogData.lead_advance_amount || ""}
                        </span>
                      </button> */}
                    </ul>
                  </div>
                  {/* {MenulistSelectedData.length > 0 && (
                    <div className="col-md-12 mb-3">
                      <h5 className="card-title">Menu List</h5>
                      <ul className="list-group">
                        {MenulistSelectedData.map((blogdddddd, index) => (
                          <button
                            className=" list-group-item-action list-group-item"
                            key={index}
                          >
                            {blogdddddd.menu_name}
                            <span className="float_right_show">
                              {RUPESSICON}{" "}
                              {blogdddddd.menu_discount_price > 0 ? (
                                <span>{blogdddddd.menu_discount_price}</span>
                              ) : (
                                <span>{blogdddddd.menu_price}</span>
                              )}
                            </span>
                          </button>
                        ))}
                      </ul>
                    </div>
                  )} */}

                  {/* {ProcesslistData.length > 0 && (
                    <div className="col-md-12 mb-3">
                      <h5 className="card-title">Enquiry Progress</h5>
                      <ul className="list-group">
                        {ProcesslistData.map((blogdddddd, index) => (
                          <button
                            className=" list-group-item-action list-group-item"
                            key={index}
                          >
                            <div className="row">
                              <div className="col-md-4 ">
                                {" "}
                                {formatDateString(blogdddddd.again_ask_date)}
                              </div>
                              <div className="col-md-2 ">
                                {" "}
                                <span className="min_center">
                                  {blogdddddd.meeting_minutes} Min{" "}
                                </span>
                              </div>
                              <div className="col-md-6 ">
                                {" "}
                                <span className="float_right_show">
                                  {blogdddddd.remark}
                                </span>
                              </div>
                            </div>
                          </button>
                        ))}
                      </ul>
                    </div>
                  )} */}
                </div>
                <form className="needs-validation" id="CarrerformData">
                  <div className="form-row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Event Name <span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        className="form-control  trio_mandatory"
                        name="lead_person_name"
                        id="lead_person_name"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Person Name"
                        defaultValue={Category || ""}
                      />
                      <span className="condition_error"></span>
                      {/* <select
                        className="form-control trio_mandatory"
                        name="event_list_name"
                        id="event_list_name"
                      > */}
                      {/* {seteventlistname.map((blogdddddd, index) => (
                          <option
                            key={index}
                            value={blogdddddd.event_list_name}
                            selected={
                              editBlogData.event_list_name ===
                              blogdddddd.event_list_name
                            }
                          >
                            {blogdddddd.event_list_name}
                          </option>
                        ))} */}
                      {/* </select> */}
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Person Name<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="lead_person_name"
                        id="lead_person_name"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Person Name"
                        defaultValue={editBlogData.lead_person_name || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">Company Name</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_company_name"
                        id="lead_person_company_name"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter  Company Name"
                        defaultValue={
                          editBlogData.lead_person_company_name || ""
                        }
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Mobile No<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory trio_no"
                        name="lead_person_mobile_no"
                        id="lead_person_mobile_no"
                        maxLength={10}
                        onInput={handleNumbersChange}
                        placeholder="Enter Mobile No"
                        defaultValue={editBlogData.lead_person_mobile_no || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01"> Email ID</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_email_id"
                        id="lead_person_email_id"
                        maxLength={100}
                        onInput={handleEmailChange}
                        placeholder="Enter Email"
                        defaultValue={editBlogData.lead_person_email_id || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Address<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="lead_person_address"
                        id="lead_person_address"
                        maxLength={200}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Address"
                        defaultValue={editBlogData.lead_person_address || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="form-row hidden">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01"> City</label>
                      <input
                        type="text"
                        className="form-control  searchInput_google"
                        name="lead_person_city"
                        id="lead_person_city"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City"
                        defaultValue={editBlogData.lead_person_city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01"> State</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_state"
                        id="lead_person_state"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter State"
                        defaultValue={editBlogData.lead_person_state || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Country</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_country"
                        id="lead_person_country"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Country"
                        defaultValue={
                          editBlogData.lead_person_country || "India"
                        }
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Search City<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="searchInput"
                        id="searchInput"
                        maxLength={200}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City Name"
                        defaultValue={editBlogData.lead_person_city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Event Date<span className="red_show">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control  trio_mandatory"
                        name="lead_event_date"
                        id="lead_event_date"
                        min={computeTodayDate()} // Replace with the maximum date you want to allow
                        max={computeFutureDate()} // Replace with the maximum date you want to allow
                        placeholder="Enter Event date"
                        onChange={handleDateChange}
                        defaultValue={editBlogData.lead_event_date || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    {/* <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Event Timing<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_for_eventtiming_full"
                        id="lead_for_eventtiming_full"
                      > */}
                    {/* {EventTiminglistData.map((blogdddddd, index) => (
                          <option
                            key={index}
                            value={
                              blogdddddd.primary_id +
                              "~@~" +
                              blogdddddd.timing_name
                            }
                            selected={
                              editBlogData.lead_for_eventtiming_id ===
                              blogdddddd.primary_id
                            }
                          >
                            {blogdddddd.timing_name}({" "}
                            {blogdddddd.timing_from +
                              "-" +
                              blogdddddd.timing_to}
                            )
                          </option>
                        ))} */}
                    {/* </select>

                      <span className="condition_error"></span>
                    </div> */}
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        No of Guest<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory length_min"
                        name="lead_event_no_of_person_come"
                        id="lead_event_no_of_person_come"
                        maxLength={5}
                        onInput={handleNumbersChange}
                        onChange={NoOfGuestChange}
                        placeholder="Enter No of Guest"
                        defaultValue={
                          editBlogData.lead_event_no_of_person_come || ""
                        }
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="form-row">
                    {/* <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">
                        Menu Selected<span className="red_show">*</span>
                      </label>
                      <select
                        multiple
                        id="menu_selected_id"
                        name="menu_selected_id"
                        className="form-control  trio_mandatory"
                        onChange={handleMenuChange}
                      >
                       {MenulistData.map((blogdddddd, index) => {
                          const optionValue =
                            blogdddddd.primary_id +
                            "~@~" +
                            blogdddddd.menu_name +
                            "~@~" +
                            blogdddddd.menu_price +
                            "~@~" +
                            blogdddddd.menu_discount_price;

                          const isSelected = MenulistSelectedData.some(
                            (item) => {
                              return (
                                Number(item.menu_id) === blogdddddd.primary_id
                              );
                            }
                          );
                          return (
                            <option
                              key={index}
                              value={optionValue}
                              selected={isSelected}
                            >
                              {blogdddddd.menu_discount_price > 0
                                ? `${
                                    blogdddddd.menu_name
                                  } ${RUPESSICON} ${Number(
                                    blogdddddd.menu_discount_price
                                  )} Per Person`
                                : `${
                                    blogdddddd.menu_name
                                  } ${RUPESSICON} ${Number(
                                    blogdddddd.menu_price
                                  )} Per Person`}
                            </option>
                          );
                        })} 
                      </select>
                      <span className="condition_error"></span>
                    </div> */}
                  </div>
                  <div className="form-row">
                    <div className=" col-md-12 mb-6 ">
                      <label htmlFor="validationCustom01">
                        Extra Add on's<span className="red_show">*</span>
                      </label>
                      <div className=" col-md-12 mb-3 d-flex ">
                        {editBlogData &&
                          editBlogData.addon_data &&
                          editBlogData.addon_data.map((item, index) => (
                            <div className="form-check col-md-3" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={`${item.primary_id}@${item.add_on_name}@${item.add_on_price}`}
                                id={`flexCheckDefault${index}`}
                                onChange={handleextraaddon}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexCheckDefault${index}`}
                              >
                                {item.add_on_name} ₹ {item.add_on_price}
                              </label>
                            </div>
                          ))}
                      </div>
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Package Name <span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_for_event_package"
                        id="lead_for_event_package"
                        // onChange={(e) => setTotalAmount(e.target.value)}
                        onChange={(e) => handleAmountData(e.target.value)}
                      >
                        <option value="0">Select Package</option>
                        {editBlogData && editBlogData.package_data ? (
                          editBlogData.package_data.map((blogdddddd, index) => (
                            <option
                              key={blogdddddd.primary_id}
                              value={`${blogdddddd.package_price}~@~${blogdddddd.primary_id}`}
                              selected={
                                editBlogData?.lead_for_event_package ===
                                blogdddddd.primary_id
                              }
                            >
                              {blogdddddd.package_title}
                            </option>
                          ))
                        ) : (
                          <option>No packages available</option>
                        )}
                      </select>

                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Total Amount <span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        className="form-control  length_min"
                        name="lead_total_amount"
                        id="lead_total_amount"
                        maxLength={10}
                        onInput={handleNumbersDecimalChange}
                        onBlur={handleBlur}
                        placeholder="Enter Total Amount"
                        defaultValue={totalAmount}
                        onChange={handleTotalAmountChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Addon Amount <span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_mandatory length_min"
                        name="add_on_amount"
                        id="add_on_amount"
                        maxLength={10}
                        onBlur={handleBlur}
                        onInput={handleNumbersDecimalChange}
                        placeholder="Enter Addon Amount"
                        value={AddOnAmount}
                        onChange={handleAddonChange}
                        disabled
                      />
                      <span className="condition_error"></span>
                    </div>

                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        GST% <span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory length_min"
                        name="gst_per"
                        id="gst_per"
                        value={selectedValue}
                        onChange={handleSelectChange}
                      >
                        <option value="0">0%</option>
                        <option value="5">5%</option>
                        <option value="12">12%</option>
                        <option value="18">18%</option>
                        <option value="28">28%</option>
                      </select>
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Final Amount <span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_mandatory length_min"
                        name="final_amount_set"
                        readOnly
                        id="final_amount_set"
                        maxLength={10}
                        defaultValue={finaltotalAmount}
                        placeholder="Enter Final Amount"
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3 hidden">
                      <label htmlFor="validationCustom01">
                        Final Amount <span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_mandatory length_min"
                        name="final_amount"
                        id="final_amount"
                        maxLength={10}
                        defaultValue={finaltotalAmount}
                        placeholder="Enter Final Amount"
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Due Amount <span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_mandatory length_min"
                        name="lead_due_amount"
                        id="lead_due_amount"
                        maxLength={10}
                        readOnly
                        placeholder="Enter Due Amount"
                        defaultValue={dueAmount}
                        onChange={handleAdvanceAmountChange}
                      />
                      <span
                        style={{ fontWeight: "400", fontSize: "14px" }}
                        className="condition_error"
                      >
                        {error}
                      </span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Advance Amount<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_mandatory length_min"
                        name="lead_advance_amount"
                        id="lead_advance_amount"
                        onBlur={handleBlur}
                        maxLength={10}
                        onInput={handleNumbersDecimalChange}
                        placeholder="Enter Advance Amount"
                        defaultValue={advanceAmount}
                        onChange={handleAdvanceAmountChange}
                      />
                      <span
                        style={{ fontWeight: "400", fontSize: "14px" }}
                        className="condition_error"
                      >
                        {error}
                      </span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">
                        Special Requirement
                      </label>

                      <textarea
                        type="text"
                        className="form-control  "
                        name="special_requirement"
                        id="special_requirement"
                        maxLength={400}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Special Requirement"
                        defaultValue={editBlogData.special_requirement || ""}
                      ></textarea>
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "CarrerformData",
                        confirm_enquiry
                      )
                    }
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default EnquiryDashboardConfirm;
