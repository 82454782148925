import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  save_update_staff,
  get_all_staff,
  get_all_venue_data,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handlePasswordChange,
  handleSuccessSession,
  handleError,
  computeTodayDate,
  computeFutureDate,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
function StaffDashboardAdd() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editBlogData, seteditBlogData] = useState([]);
  const [checkStaffData, setcheckStaffData] = useState("0");
  const [venueData, setVenueData] = useState([]);
  const [venueidData, setVenueidData] = useState("0");
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedAdmin = retrieveData("admin_profession");
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedVenueIds, setSelectedVenueIds] = useState([]);
  console.log(venueidData);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    if (parts.length !== 1) {
      const start_date = "";
      const end_date = "";
      const flag = "3";
      let call_id = parts[1];
      master_data_get(start_date, end_date, flag, call_id);
    }

    const input = document.getElementById("searchInput");
    try {
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ["(cities)"], // Restrict results to cities
      });

      autocomplete.addListener("place_changed", function () {
        const place = autocomplete.getPlace();
        let full_address = place.address_components;
        let length_data = place.address_components.length;
        let citys = "";
        let state = "";
        let country = "";
        let tehsil = "";

        for (let i = 0; i < length_data; i++) {
          if (full_address[i].types[0] === "administrative_area_level_1") {
            state = full_address[i].long_name;
          } else if (full_address[i].types[0] === "country") {
            country = full_address[i].long_name;
          } else if (
            full_address[i].types[0] === "administrative_area_level_2"
          ) {
            citys = full_address[i].long_name;
          } else if (full_address[i].types[0] === "locality") {
            tehsil = full_address[i].long_name;
          }
        }
        if (tehsil !== "") {
          citys = tehsil;
        }
        document.getElementById("admin_city").value = citys;
        document.getElementById("admin_state").value = state;
        document.getElementById("admin_country").value = country;
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    master_data_get1();
  }, []);
  const master_data_get1 = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_vendor", retrievedAdmin);
    fd.append("call_id", retrievedAdminId);
    await server_post_data(get_all_venue_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data.message);
          setVenueData(Response.data.message.venue);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_staff, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data);
          seteditBlogData(Response.data.message.data_staff[0]);
          setEditorDatMainID(Response.data.message.data_staff[0].primary_id);
          seteditOldImageData(Response.data.message.data_staff[0].admin_image);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("admin_id", retrievedAdminId);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("old_image_link", editOldImageData);
      fd_from.append("venueidData", venueidData);
      fd_from.append("selectedVenueIds", selectedVenueIds);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/staffDashboardView");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };

  const handleVenueSelection = (e) => {
    // Get selected options
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedVenueIds(selectedOptions);
  };
  console.log(venueData);
  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Staff Management
                    <div className="page-title-subheading">
                      Add and Edit Staff
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Add/Edit Staff</h5>
                <form className="needs-validation" id="CarrerformData">
                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Name<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_name  trio_mandatory"
                        name="admin_name"
                        id="admin_name"
                        maxLength={35}
                        required
                        onInput={handleAphabetsChange}
                        placeholder="Enter Name"
                        defaultValue={editBlogData.admin_name || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Mobile No<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory trio_no"
                        name="admin_mobile_no"
                        id="admin_mobile_no"
                        maxLength={10}
                        onInput={handleNumbersChange}
                        placeholder="Enter Mobile No"
                        defaultValue={editBlogData.admin_mobile_no || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Email Id<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory trio_email"
                        name="admin_email"
                        autoComplete="off" // or "off" as needed
                        id="admin_email"
                        maxLength={60}
                        onInput={handleEmailChange}
                        placeholder="Enter Email Id"
                        defaultValue={editBlogData.admin_email || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row hidden">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01"> City</label>
                      <input
                        type="text"
                        className="form-control  searchInput_google"
                        name="admin_city"
                        id="admin_city"
                        maxLength={200}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City"
                        defaultValue={editBlogData.admin_city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01"> State</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="admin_state"
                        id="admin_state"
                        maxLength={45}
                        onInput={handleAphabetsChange}
                        placeholder="Enter State"
                        defaultValue={editBlogData.admin_state || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">Country</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="admin_country"
                        id="admin_country"
                        maxLength={45}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Country"
                        defaultValue={editBlogData.admin_country || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Search City<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control trio_mandatory "
                        name="searchInput"
                        id="searchInput"
                        maxLength={200}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City Name"
                        defaultValue={editBlogData.admin_city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Zipcode<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory trio_zip"
                        name="admin_zipcode"
                        id="admin_zipcode"
                        maxLength={6}
                        onInput={handleNumbersChange}
                        placeholder="Enter Zipcode"
                        defaultValue={editBlogData.admin_zipcode || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    {/* <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Education<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="admin_education"
                        id="admin_education"
                        maxLength={50}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Education"
                        defaultValue={editBlogData.admin_education || ""}
                      />
                      <span className="condition_error"></span>
                    </div> */}
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Joining Date<span className="red_show">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control  trio_mandatory"
                        name="admin_join_date"
                        id="admin_join_date"
                        placeholder="Enter Join Date"
                        defaultValue={editBlogData.admin_join_date || ""}
                        min={computeTodayDate()}
                        max={computeFutureDate()}
                      />
                      <span className="condition_error"></span>
                    </div>
                    {/* <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">Leave Date</label>
                      <input
                        type="date"
                        className="form-control  trio_mandatory"
                        name="admin_leave_date"
                        id="admin_leave_date"
                        placeholder="Enter Leave Date"
                        defaultValue={editBlogData.admin_leave_date || ""}
                      />
                      <span className="condition_error"></span>
                    </div> */}
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Admin Type<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="admin_type"
                        id="admin_type"
                        onChange={(e) => setcheckStaffData(e.target.value)}
                      >
                        <option value="">Select Type</option>
                        {retrievedAdmin === "0" ? (
                          <>
                            <option
                              value="0"
                              selected={editBlogData.admin_vendor === "0"}
                            >
                              Super Admin
                            </option>
                            <option
                              value="2"
                              selected={editBlogData.admin_vendor === "0"}
                            >
                              Venue Admin
                            </option>
                          </>
                        ) : (
                          ""
                        )}
                        <option
                          value="1"
                          selected={editBlogData.admin_vendor === "1"}
                        >
                          Staff
                        </option>
                      </select>
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Password<span className="red_show">*</span>
                      </label>
                      <div className="passwordDiv">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control trio_mandatory trio_password"
                          name="admin_password"
                          id="admin_password"
                          placeholder="Enter Password"
                          defaultValue={editBlogData.admin_password || ""}
                          minLength={8}
                          maxLength={20}
                        />
                        <button
                          className="eye_btn_ eye_btn_position"
                          type="button"
                          onClick={handleTogglePassword}
                        >
                          {showPassword ? (
                            <AiFillEyeInvisible />
                          ) : (
                            <AiFillEye />
                          )}
                        </button>
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  {checkStaffData == "1" ? (
                    <div className="form-row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom01">
                          Assign Venue<span className="red_show">*</span>
                        </label>
                        <select
                          className="form-control trio_mandatory"
                          name="venue_type"
                          id="venue_type"
                          // placeholder= "Select Type"
                          multiple
                          onChange={handleVenueSelection}
                          // onChange={(e) => setVenueidData(e.target.value)}
                        >
                          <option value="0">Select Type</option>
                          {venueData &&
                            venueData.length > 0 &&
                            venueData.map((e) => (
                              <option
                                value={e.primary_id}
                                // selected={editBlogData.admin_vendor === "0"}
                              >
                                {e.venue_name}
                              </option>
                            ))}
                        </select>
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Images
                        <span style={{ color: "red", fontWeight: "600" }}>
                          (size 200*200 max)
                        </span>
                      </label>
                      <br></br>
                      <input
                        style={{ marginBottom: "1rem" }}
                        className="inputEditFile"
                        type="file"
                        name="admin_image"
                        onChange={handleFileChangedynamic("admin_image")}
                        accept=".jpg,.jpeg,.png"
                      />
                      <div className="previewEditBox">
                        {dynaicimage && dynaicimage.admin_image_show && (
                          <img
                            src={dynaicimage.admin_image_show}
                            alt="Preview"
                            className="imgEditMaster"
                          />
                        )}
                      </div>
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "CarrerformData",
                        save_update_staff
                      )
                    }
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default StaffDashboardAdd;
