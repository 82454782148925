import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  get_all_amenities,
  save_venue_category,
  get_venue_category,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handlePasswordChange,
  handleSuccessSession,
  handleError,
  computeTodayDate,
  computeFutureDate,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link, useParams } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
function CategoryVenueAdd() {
  const { id, eid } = useParams();
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editBlogData, seteditBlogData] = useState([]);
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = retrieveData("staff_id");
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedValue, setSelectedValue] = useState("0");
  const [masterAmenities, setMasterAmenities] = useState();

  useEffect(() => {
    master_data_get1();
  }, []);
  const master_data_get1 = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 4);
    await server_post_data(get_venue_category, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setMasterAmenities(Response.data.message.category);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  // console.log(id);
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    setshowLoaderAdmin(true);
    let fd_from = combiled_form_data(form_data, dynaicimage);
    fd_from.append("venue_id", id);
    fd_from.append("category_id", selectedValue);
    await server_post_data(url_for_save, fd_from)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccessSession(
            Response.data.message,
            `/VenueDeshboardEdit/${id}/CategoryVenueView`
          );
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Venue Category Management
                    <div className="page-title-subheading">
                      Add and Edit Venue Category
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Add/Edit Venue Category</h5>
                <form className="needs-validation" id="CarrerformData">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom01">
                      Category <span className="red_show">*</span>
                    </label>
                    <select
                      className="form-control trio_mandatory length_min"
                      name="gst_per"
                      id="gst_per"
                      value={selectedValue}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select Category</option>
                      {masterAmenities && masterAmenities.length > 0 ? (
                        masterAmenities.map((e) => (
                          <option key={e.primary_id} value={e.primary_id}>
                            {e.category_master_name}
                          </option>
                        ))
                      ) : (
                        <option value="">Data Not Found</option>
                      )}
                    </select>
                    <span className="condition_error"></span>
                  </div>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "CarrerformData",
                        save_venue_category
                      )
                    }
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default CategoryVenueAdd;
