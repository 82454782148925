import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  save_update_city,
  city_list,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleIaphabetnumberChange,
  handleNumbersRatingChange,
  handleSuccessSession,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
function AdminCityAdd() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editBlogData, seteditBlogData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = retrieveData("staff_id");
  const [dynaicimage, setDynaicimage] = useState(null);
  const [dynaicimage1, setDynaicimage1] = useState(null);

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    if (parts.length !== 1) {
      const start_date = "";
      const end_date = "";
      const flag = "3";
      const call_id = parts[1];
      master_data_get(start_date, end_date, flag, call_id);
    }
  }, []);
  console.log(editorDataMainID);
  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(city_list, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditBlogData(Response.data.message[0]);
          setEditorDatMainID(Response.data.message[0].primary_id);
          setDynaicimage1(Response.data.message[0].image2);
          setDynaicimage(Response.data.message[0].image1);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("admin_id", retrievedAdminId);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("admin_image", dynaicimage);
      fd_from.append("admin_image1", dynaicimage1);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/AdminCityView");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };


  useEffect(() => {
    
    const input = document.getElementById("searchInput");
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: ["(cities)"], // Restrict results to cities
    });

    autocomplete.addListener("place_changed", function () {
      const place = autocomplete.getPlace();
      let full_address = place.address_components;
      let length_data = place.address_components.length;
      let citys = "";
      let state = "";
      let country = "";
      let tehsil = "";

      for (let i = 0; i < length_data; i++) {
        if (full_address[i].types[0] === "administrative_area_level_1") {
          state = full_address[i].long_name;
        } else if (full_address[i].types[0] === "country") {
          country = full_address[i].long_name;
        } else if (full_address[i].types[0] === "administrative_area_level_2") {
          citys = full_address[i].long_name;
        } else if (full_address[i].types[0] === "locality") {
          tehsil = full_address[i].long_name;
        }
      }
      if (tehsil !== "") {
        citys = tehsil;
      }
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      document.getElementById("city").value = citys;
      document.getElementById("state").value = state;
      document.getElementById("country").value = country;
      document.getElementById("lat").value = latitude;
      document.getElementById("lng").value = longitude;
    });
  
  }, []);

  const handleFileChangedynamic1 = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage1((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage1((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Website Management &gt; City
                    <div className="page-title-subheading">
                      Add and Edit City
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Add/Edit City</h5>
                <form className="needs-validation" id="CarrerformData">
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">
                        City<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="searchInput"
                        id="searchInput"
                        maxLength={100}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Title"
                        defaultValue={editBlogData.city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-12 mb-3 hidden">
                      <label htmlFor="validationCustom01">
                        City<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="city"
                        id="city"
                        maxLength={100}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Title"
                        defaultValue={editBlogData.city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-6 mb-3 hidden">
                      <label htmlFor="validationCustom01">
                        State<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="state"
                        id="state"
                        maxLength={35}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Name"
                        defaultValue={editBlogData.state || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row hidden">
                    <div className="col-md-12 mb-6">
                      <label htmlFor="validationCustom01">
                        Country<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="country"
                        id="country"
                        maxLength={150}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Details"
                        defaultValue={editBlogData.country || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-12 mb-6">
                      <label htmlFor="validationCustom01">
                        Country<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="lat"
                        id="lat"
                        maxLength={150}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Details"
                        defaultValue={editBlogData.country || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-12 mb-6">
                      <label htmlFor="validationCustom01">
                        Country<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="lng"
                        id="lng"
                        maxLength={150}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Details"
                        defaultValue={editBlogData.country || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Images
                        <span className="red_show">
                          *
                          <span style={{ color: "red", fontWeight: "600" }}>
                            (size 400 x 400 upto 500kb)
                          </span>
                        </span>
                      </label>
                      <br></br>
                      <input
                        style={{ marginBottom: "1rem" }}
                        className="inputEditFile"
                        type="file"
                        name="admin_image"
                        onChange={handleFileChangedynamic("admin_image")}
                        accept=".jpg,.jpeg,.png"
                      />
                      <div className="previewEditBox">
                        {dynaicimage && dynaicimage.admin_image_show && (
                          <img
                            src={dynaicimage.admin_image_show}
                            alt="Preview"
                            className="imgEditMaster"
                          />
                        )}
                      </div>
                      <span className="condition_error"></span>
                    </div>
                    {/* </div>
                  <div className="form-row"> */}
                    {/* <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Images
                        <span className="red_show">
                          *
                          <span style={{ color: "red", fontWeight: "600" }}>
                            (size 400 x 400 upto 500kb)
                          </span>
                        </span>
                      </label>
                      <br></br>
                      <input
                        style={{ marginBottom: "1rem" }}
                        className="inputEditFile"
                        type="file"
                        name="admin_image1"
                        onChange={handleFileChangedynamic1("admin_image")}
                        accept=".jpg,.jpeg,.png"
                      />
                      <div className="previewEditBox">
                        {dynaicimage1 && dynaicimage1.admin_image_show && (
                          <img
                            src={dynaicimage1.admin_image_show}
                            alt="Preview"
                            className="imgEditMaster"
                          />
                        )}
                      </div>
                      <span className="condition_error"></span>
                    </div> */}
                  </div>
                  <button
                    className="btn btn-primary mt-4"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "CarrerformData",
                        save_update_city
                      )
                    }
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default AdminCityAdd;
